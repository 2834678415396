<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { VueVault } from '@apideck/vue-vault';
import Icon from '@/components/ui/Icon';
import Popover from '@/components/ui/Popover.vue';
import ConfirmModal from '@/components/ui/ConfirmModal.vue';
import { trackEvent } from '@/lib/rudderstack-helper';

const store = useStore();

const jwtToken = computed(() => store.getters['integrations/jwtToken']);

// A 'change' event is emitted when the connections have changed (either from a new connection or a disconnection)
// A 'close' event is emitted when the APIDeck vault modal is closed
const emit = defineEmits(['change', 'close']);

const showConfirmDisconnect = ref(false);

const props = defineProps({
  serviceId: {
    type: String,
    required: true,
  },
  serviceName: {
    type: String,
    required: true,
  },
  serviceIconUrl: {
    type: String,
    required: true,
  },
  isConnected: {
    type: Boolean,
    default: false,
  },
  unifiedApi: {
    type: String,
    default: 'accounting',
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  canInitiateImport: {
    type: Boolean,
    default: false,
  },
  onActionButtonClick: {
    type: Function,
    default: undefined,
  },
});

const canOpenVault = computed(() => props.isConnected && !props.isDisabled);

const onClose = () => {
  emit('close');
};

const handleManageIntegration = (vaultProps) => {
  if (!canOpenVault.value) {
    return;
  }

  vaultProps.open();

  trackEvent('integration_vault_open', {
    service: props.serviceName,
    intent: 'manage_integration',
  });
};

const onConnectionChange = (connection) => {
  emit('change', connection);
};

const handleDisconnectClick = () => {
  showConfirmDisconnect.value = true;
  trackEvent('integration_disconnect_click', {
    service: props.serviceName,
  });
};

const handleActionButtonClick = (vaultProps) => {
  if (props.onActionButtonClick) {
    props.onActionButtonClick();
  } else {
    vaultProps.open();
  }

  // Log the event
  if (props.canInitiateImport) {
    trackEvent('integration_start_import', {
      service: props.serviceName,
    });
  } else {
    trackEvent('integration_vault_open', {
      service: props.serviceName,
      intent: 'connect_integration',
    });
  }
};

const handleIntegrationListItemClick = (vaultProps) => {
  if (!props.isDisabled) {
    handleActionButtonClick(vaultProps);
  }
};

const confirmServiceDisconnect = async () => {
  await store.dispatch('integrations/deleteConnection', props.serviceId);
  showConfirmDisconnect.value = false;
  emit('change');
  emit('close');
};
</script>

<template>
  <VueVault
    v-slot="vaultProps"
    :token="jwtToken"
    :unified-api="unifiedApi"
    :service-id="serviceId"
    :on-close="onClose"
    :on-connection-change="onConnectionChange"
  >
    <div
      :id="`${serviceId}-container`"
      class="integration p-3 border border-solid border-default hover:bg-transparent rounded-lg"
      :class="{
        disabled: isDisabled,
        'hover:border-emphasis hover:bg-muted': !isConnected
      }"
      @click="handleIntegrationListItemClick(vaultProps)"
    >
      <ConfirmModal
        v-if="showConfirmDisconnect"
        :title="`Are you sure you want to disconnect the integration with ${serviceName}?`"
        confirm-type="alert"
        confirm-text="Disconnect"
        @close="showConfirmDisconnect = false"
        @confirm="confirmServiceDisconnect"
      />

      <div class="integration-details-container">
        <img
          :src="serviceIconUrl"
          :alt="`${serviceName} icon`"
          class="integration-icon"
        >
        <p
          class="integration-text text-base text-emphasis font-semibold"
        >
          {{ serviceName }}
        </p>

        <span
          v-if="isConnected"
          class="integration-connected"
        >Connected</span>

        <Popover v-if="isConnected">
          <template #trigger>
            <button
              type="button"
              class="integration-settings-button hover:bg-brand-50 rounded w-6 h-6 ml-4"
              data-cy="integration-settings-icon"
              @click.stop.prevent
            >
              <Icon
                class="h-4 w-4 text-default"
                type="more-horiz"
              />
            </button>
          </template>

          <template #content>
            <div class="integration-settings-list links p-0">
              <button
                v-if="!isDisabled"
                v-close-popper
                class="link hover:bg-brand-50 rounded hover:text-emphasis"
                @click="handleManageIntegration(vaultProps)"
              >
                <Icon
                  class="text-inherit"
                  type="settings"
                /> Manage
              </button>
              <button
                v-close-popper
                class="link integration-disconnect-button hover:bg-brand-50 rounded"
                @click="handleDisconnectClick"
              >
                <Icon type="broken-link" /> Disconnect
              </button>
            </div>
          </template>
        </Popover>
      </div>
    </div>
  </VueVault>
</template>

<style lang="scss">
.integration {
  max-width: 500px;
  padding: 16px 24px;
  background-color: #FFFFFF;
  border: 1px solid #E7E4E6;
  border-radius: 8px;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  &:hover {
    border-color: #898088;
    background-color: #F5F4F5;
  }

  .integration-details-container {
    display: flex;
    align-items: center;
    .integration-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .integration-text {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin: 0 auto 0 0;
      color: #1B181B;
    }
    .integration-connected {
      padding: 4px 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: #145229;
      background: #E4F4E9;
      border-radius: 9999px;
    }
    .integration-settings-button {
      display: flex;
      align-items: center;
      appearance: none;
      background-color: inherit;
      border: none;
      margin-left: 16px;
      border-radius: 4px;
      padding: 6px;
      &:hover {
        background-color: #E0DCDF;
      }

      svg {
        width: 20px;
        height: 20px;
        color: #564D54;
      }
    }
    .integration-button {
      background: #FFFFFF;
      border: 1px solid #D3CFD2;
      border-radius: 8px;
      padding: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      color: #1B181B;
      transition: background-color 0.2s;
      &:hover {
        background-color: #F4F4F4
      }
    }
  }
}
.kn-popover .integration-settings-list.links .integration-disconnect-button.link,
.kn-popover .integration-settings-list.links .integration-disconnect-button.link:hover svg {
  color: $red;
}
</style>
