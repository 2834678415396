import isEmpty from 'lodash/isEmpty';
import hasIn from 'lodash/hasIn';
import uniqueId from 'lodash/uniqueId';
import store from '@/store';
import { LEFT_PANEL_MIN_WIDTH_PX } from '@/store/modules/ui/ui';

const storeState = {
  all: [],
  checkedRecordIds: [],
  isDraggingNewView: false,
  isDraggingViewItem: false,
  viewItemBeingDragged: null,

  // Used for adding items to views when a modal is needed to select a page. Tricky because it can be triggered onDrop in the view rendering.
  isAddingLinkedPage: false,
  addLinkedPageEvent: null,

  // Manage which data views use in page previews
  pagePreviewType: 'live', // live | sampe | none
  pagePreviewRecords: {},
  pagePreviewRecordIds: {},
  pagePreviewUsers: {},
  pagePreviewUserIds: {},

  // Manage pending design settings for application
  applicationHasPendingDesignChanges: false,
  appDesignSettingsBackup: {},
  designSettingsUnwatcher: null,

  // show the names of views in the page preview
  showViewNames: false,
  collapseViews: false,

  // The key of the menu you can add a new view to
  menuKeyForAddingNewView: null,
};

const storeGetters = {
  getUniqueKey: (state) => (keyPrefix) => {
    let uniqueKey = uniqueId();

    if (keyPrefix) {
      uniqueKey = `${keyPrefix}_${uniqueKey}`;
    }

    return uniqueKey;
  },
  showViewNames: (state) => state.showViewNames,
  menuKeyForAddingNewView: (state) => state.menuKeyForAddingNewView,
  collapseViews: (state) => state.collapseViews,
  applicationHasPendingDesignChanges: (state) => state.applicationHasPendingDesignChanges,
  checkedRecordIds: (state) => state.checkedRecordIds,
  isDraggingNewView: (state) => state.isDraggingNewView,

  // viewArea could be different drag/drop zones in a single veiw, like search inputs vs. search results
  isDraggingViewItem: (state) => (viewKey, viewArea) => {
    if (!state.isDraggingViewItem) {
      return false;
    }

    if (state.viewItemBeingDragged.viewKey !== viewKey) {
      return false;
    }

    if (viewArea && state.viewItemBeingDragged.viewArea !== viewArea) {
      return false;
    }

    return true;
  },
  isToolboxExpanded: (state, getters, rootState) => rootState.ui.leftPanelWidth > LEFT_PANEL_MIN_WIDTH_PX,
  toolboxExpandClass: (state) => (route, defaultClass) => {
    if (state.isToolboxExpanded) {
      return 'builderLayout_toolbox-XLarge';
    }

    if (route.meta.hasOwnProperty('widthClass')) {
      return route.meta.widthClass;
    }

    return defaultClass || '';
  },
  isHelpDrawerExpanded: (state) => (route) => hasIn(route, 'query.help'),
  isAddingLinkedPage: (state) => state.isAddingLinkedPage,
  addLinkedPageEvent: (state) => state.addLinkedPageEvent,
  pagePreviewType: (state) => state.pagePreviewType,
  pagePreviewId: (state) => {
    const { activePage } = store.getters;

    if (!activePage) {
      return;
    }

    const objectKey = activePage.getPagePreviewObjectKey();

    // login page
    const userRole = activePage.authorizedUserRoles[0];

    // details page
    if (activePage.object || !userRole) {
      return state.pagePreviewRecordIds[objectKey];
    }

    return state.pagePreviewUserIds[userRole];
  },
  pagePreviewOptions: (state) => () => {
    const { activePage } = store.getters;

    if (!activePage) {
      return;
    }

    const objectKey = activePage.getPagePreviewObjectKey();

    // login page
    const userRole = activePage.authorizedUserRoles[0];

    // details page
    if (activePage.object || !userRole) {
      return state.pagePreviewRecords[objectKey];
    }

    return state.pagePreviewUsers[userRole];
  },
};

const storeMutations = {
  menuKeyForAddingNewView(state, key) {
    state.menuKeyForAddingNewView = key;
  },
  toggleViewNames(state) {
    state.showViewNames = !state.showViewNames;
  },
  toggleViewCollapse(state) {
    state.collapseViews = !state.collapseViews;
  },
  setApplicationHasPendingDesignChanges(state, status) {
    state.applicationHasPendingDesignChanges = status;
  },
  setPagePreviewRecords(state, { objectKey, records }) {
    state.pagePreviewRecords[objectKey] = records;
  },
  setPagePreviewUsers(state, { userRole, records }) {
    state.pagePreviewUsers[userRole] = records;
  },
  setPagePreviewType(state, type) {
    state.pagePreviewType = type;
  },
  setPagePreviewRecordId(state, { objectKey, recordId }) {
    state.pagePreviewRecordIds[objectKey] = recordId;
  },
  setPagePreviewUserId(state, { userRole, recordId }) {
    state.pagePreviewUserIds[userRole] = recordId;
  },
  setCheckedRecordIds(state, recordIds) {
    state.checkedRecordIds = recordIds;
  },
  dragNewViewStart(state) {
    state.isDraggingNewView = true;
  },
  dragNewViewEnd(state) {
    state.isDraggingNewView = false;
  },
  dragStartNewViewItem(state, { viewKey, viewArea }) {
    state.isDraggingViewItem = true;
    state.viewItemBeingDragged = {
      viewKey,
      viewArea,
    };
  },
  dragEndNewViewItem(state) {
    state.isDraggingViewItem = false;
    state.viewItemBeingDragged = null;
  },
  dragStartSortViewItem(state, { viewKey, viewArea }) {
    state.isDraggingViewItem = true;
    state.viewItemBeingDragged = {
      viewKey,
      viewArea,
    };
  },
  dragEndSortViewItem(state) {
    state.isDraggingViewItem = false;
    state.viewItemBeingDragged = null;
  },
  addLinkedPageStart(state, event = null) {
    state.isAddingLinkedPage = true;
    state.addLinkedPageEvent = event;
  },
  addLinkedPageEnd(state) {
    state.isAddingLinkedPage = false;
    state.addLinkedPageEvent = null;
  },
};

const storeActions = {
  createAppDesignSettingsBackup({ state, commit }) {
    state.appDesignSettingsBackup = JSON.parse(JSON.stringify(store.getters.app.design));

    if (state.designSettingsUnwatcher) {
      state.designSettingsUnwatcher();
    }

    // The store watch() function returns an unwatch function that stops the watch we can use when restore
    state.designSettingsUnwatcher = store.watch(
      () => store.getters.app.design,
      () => {
        commit('setApplicationHasPendingDesignChanges', true);
      },
      {
        deep: true,
      },
    );
  },
  restoreAppDesignSettingsBackup({ state, commit, dispatch }) {
    // Call the unwatcher so we don't trigger changes with the restore
    state.designSettingsUnwatcher();

    // Restore backup
    store.getters.app.design = state.appDesignSettingsBackup;

    // Reset pending status
    commit('setApplicationHasPendingDesignChanges', false);

    // Set a new backup to prevent update by reference.
    dispatch('createAppDesignSettingsBackup');
  },
  async loadPagePreviewRecords({ state, commit }, objectKey) {
    if (isEmpty(state.pagePreviewRecords[objectKey])) {
      // TODO::
      /*
      let rules = [{
        field: this.identifierField.key,
        operator: `is not blank`
      }]
      */

      const filters = [];
      const recordsPerPage = 30;
      const { records } = await window.Knack.Api.getConnections(objectKey, filters, recordsPerPage);

      // commit preview records
      commit('setPagePreviewRecords', {
        objectKey, records,
      });

      // no records?
      if (isEmpty(records)) {
        return commit('setPagePreviewRecordId', {
          userRole: null, recordId: null,
        });
      }

      // commit previewID
      const { pagePreviewRecordId } = store.getters;

      // preview record either doesn't exist or isn't present in the preview records, so reset
      if (!pagePreviewRecordId || !records.find((record) => record.id === pagePreviewRecordId)) {
        commit('setPagePreviewRecordId', {
          objectKey, recordId: records[0].id,
        });
      }
    }
  },
  async loadPagePreviewUsers({ state, dispatch, commit }, { objectKey, userRole }) {
    // if no user we can use the records for the main user account
    if (!userRole) {
      await dispatch('loadPagePreviewRecords', objectKey);

      return;
    }

    if (isEmpty(state.pagePreviewUsers[userRole])) {
      const { key: rolesFieldKey } = store.getters.accountObject.getUserRoleField();

      // set filters to ensure we're only getting account records with the role the page is authorizing
      const filters = [
        {
          field: rolesFieldKey,
          operator: 'is',
          value: userRole,
        },
      ];
      const recordsPerPage = 30;
      const { records } = await window.Knack.Api.getConnections(objectKey, filters, recordsPerPage);

      // commit records
      commit('setPagePreviewUsers', {
        userRole, records,
      });

      // no records?
      if (isEmpty(records)) {
        return commit('setPagePreviewUserId', {
          userRole, recordId: null,
        });
      }

      // commit previewID
      const { pagePreviewUserId } = store.getters;

      if (!pagePreviewUserId || !records.find((record) => record.id === pagePreviewUserId)) {
        // preview record either doesn't exist or isn't present in the preview records, so reset
        commit('setPagePreviewUserId', {
          userRole, recordId: records[0].id,
        });
      }
    }
  },
  pagePreviewId({ state, commit }, previewId) {
    const { activePage } = store.getters;

    if (!activePage) {
      return;
    }

    const objectKey = activePage.getPagePreviewObjectKey();

    // login page
    const userRole = activePage.authorizedUserRoles[0];

    // details page
    if (activePage.object || !userRole) {
      commit('setPagePreviewRecordId', {
        objectKey, recordId: previewId,
      });

      return;
    }

    commit('setPagePreviewUserId', {
      userRole, recordId: previewId,
    });
  },
};

export default {
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
  actions: storeActions,
};
