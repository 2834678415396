<template>
  <div
    id="object-add-template"
    class="center w-[480px] mt-12"
    style="margin-top: 4em;"
  >
    <h1 class="center-align margin-bottom text-left text-emphasis text-xl font-medium mb-1 mt-0">
      Add a Table From Our Catalog
    </h1>
    <div
      id="templates"
      class="margin-bottom-double center w-[480px]"
    >
      <p class="text-default text-sm font-normal">
        Select a pre-built table to add to your app. You can add and change the fields later.
      </p>
      <div class="templates box margin-bottom bg-muted shadow-none rounded-lg">
        <div class="templates-menu">
          <a
            v-for="template in templates"
            :key="template.name"
            class="text-default rounded-lg p-2 hover:text-emphasis"
            :class="{
              'active bg-brand-100 text-emphasis': template.key === activeTemplate.key,
              'hover:bg-brand-50': template.key !== activeTemplate.key,
            }"
            :data-cy="template.name"
            @click="onClickTemplate(template)"
          >
            {{ template.name }}
          </a>
        </div>
        <div class="templates-preview">
          <h2 class="text-base text-emphasis">
            <strong>{{ activeTemplateName }}</strong>
          </h2>
          <p class="text-default text-xs font-normal mb-4">
            This table includes the following fields:
          </p>
          <ul>
            <li
              v-for="field in activeTemplate.fields"
              :key="field"
              class="text-default text-base"
            >
              {{ field }}
            </li>
          </ul>
        </div>
      </div>
      <div class="save center-align text-end">
        <a
          class="button huge save text-white p-2 bg-gradient-primary rounded-lg border-0 text-base font-medium"
          data-cy="add-template-object"
          @click="addTemplateObject"
        >
          Add Table
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RequestUtils from '@/components/util/RequestUtils';
import Obj from '@/store/models/Object';

export default {
  mixins: [
    RequestUtils,
  ],
  props: {
    isUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTemplate: null,
      templates: [
        {
          name: 'Companies',
          key: 'company',
          fields: [
            'Company Name',
            'Logo',
            'Website',
            'Industry',
            'Description',
            'Telephone',
            'Address',
            'Notes',
          ],
        },
        {
          name: 'Employees',
          key: 'employee',
          fields: [
            'Person',
            'Image',
            'Email',
            'Phone',
            'Position',
            'Start Date',
            'Notes',
          ],
        },
        {
          name: 'Contacts',
          key: 'contact',
          fields: [
            'Person',
            'Image',
            'Birthday',
            'Address',
            'Email',
            'Phone',
            'Occupation',
            'Notes',
          ],
        },
        {
          name: 'Products',
          key: 'product',
          fields: [
            'Person',
            'Product ID',
            'Image',
            'Description',
            'Price',
            'Available',
          ],
        },
        {
          name: 'Orders',
          key: 'order',
          fields: [
            'Order ID',
            'Status',
            'Description',
            'Total',
            'Order Date',
            'Ship Date',
            'Customer',
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'app',
      'objects',
    ]),
    activeTemplateName() {
      return this.activeTemplate ? this.activeTemplate.name : '';
    },
    backLink() {
      return `${this.$route.path.split('/objects')[0]}/objects`;
    },
  },
  created() {
    this.activeTemplate = this.templates[0];
    this.notifyErrors = true;
  },
  methods: {
    onClickTemplate(template) {
      this.activeTemplate = template;
    },
    addTemplateObject() {
      let newObjectName = this.activeTemplate.name;

      let sameNamesCount = 1;
      let checkForMatch = true;

      while (checkForMatch) {
        // only append a number to the name to compare if greater than 1
        const nameToCompare = (sameNamesCount === 1) ? newObjectName : `${newObjectName} ${String(sameNamesCount)}`;

        // does name already exist
        if (this.objects.find((obj) => obj.name === nameToCompare)) {
          // iterate count so we can also check against further numbers e.g. Contacts 2, Contacts 3
          sameNamesCount++;
        } else {
          // no match found, so use this
          newObjectName = nameToCompare;

          // we're done!
          checkForMatch = false;
        }
      }

      const newObject = new Obj({
        name: newObjectName,
        user: this.isUser,
      });

      this.commitRequest({
        validate: () => newObject.validate(),
        request: () => newObject.create(this.activeTemplate.key),
        onSuccess: ({ object }) => {
          this.$router.push(`${this.backLink}/${object.key}`);
        },
      });
    },
  },
};
</script>

<style lang="scss">
#templates {

  width: 600px;

  .box {
    display: flex;
    flex-direction: row;
    align-content: space-around;
  }
}

.templates-menu {
  width: 25%;
  display: flex;
  flex-direction: column;
  margin-right: 1em;

  a {
    padding: .35em .5em;
    border-radius: .33em;
    margin-bottom: .4em;

    &.active {
      color: $fuchsia-text;
      background: #eee;
    }

    &:hover {
      color: $fuchsia-text;
    }
  }
}

.templates-preview {
  padding-left: 5%;
  border-left: 1px solid #e9e9e9;

  ul {
    list-style: disc;
    list-style-position: inside;
    margin-left: 1em;
    margin-bottom: 1em;

    li {
      margin-bottom: .25em;
    }
  }
}
</style>
