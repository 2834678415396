<template>
  <div>
    <Popover
      ref="popover"
      :open="isOpen"
      :placement="popoverPlacement"
      :show-arrow="false"
      :content-wrapper-classes="['bg-default']"
      @show="setIsOpen(true)"
      @hide="onPopoverHide"
    >
      <template #trigger>
        <IconButton
          ref="iconButton"
          v-tippy="tooltipSettings"
          class="hover:bg-subtle"
          :class="{ 'bg-subtle text-default': isOpen }"
          icon-classes="text-default"
          :active="isOpen"
          :icon-type-primary="pageAccessIconType"
          @click="onIconButtonClick"
        />
      </template>

      <template #content>
        <PageAccessInfoCard
          :page="page"
          :user-roles-with-page-access="userRolesWithPageAccess"
        />
      </template>
    </Popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  getUserRolesWithPageAccessFriendly,
  getPageAccessDescriptionMarkup,
} from '@/lib/page/page-helper';

import IconButton from '@/components/ui/IconButton';
import Popover from '@/components/ui/Popover';
import PageAccessInfoCard from '@/components/pages/page/PageAccessInfoCard';
import { trackEvent } from '@/lib/rudderstack-helper';

export default {
  components: {
    IconButton,
    Popover,
    PageAccessInfoCard,
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    popoverPlacement: {
      type: String,
      default: 'bottom-end',
    },
    tooltipPlacement: {
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters([
      'userObjectsByProfileKey',
    ]),
    isPublicNonLoginPage() {
      const noUserRoleRestrictions = this.userRolesWithPageAccess.length === 0;
      const isLoginPage = this.page.type === 'authentication';

      return noUserRoleRestrictions && !isLoginPage;
    },
    pageAccessIconType() {
      return this.isPublicNonLoginPage ? 'lock-open-outline' : 'lock';
    },
    tooltipSettings() {
      const content = getPageAccessDescriptionMarkup({
        page: this.page,
        userRolesWithPageAccess: this.userRolesWithPageAccess,
      });

      return {
        content,
        allowHTML: true,
        placement: this.tooltipPlacement,
        delay: [500, 0],
      };
    },
    userRolesWithPageAccess() {
      return getUserRolesWithPageAccessFriendly({
        page: this.page,
        userObjectsByProfileKey: this.userObjectsByProfileKey,
      });
    },
  },
  methods: {
    hide() {
      if (this.$refs.popover) {
        this.$refs.popover.hide();
      }
    },
    onIconButtonClick() {
      this.setIsOpen(true);

      trackEvent('login_indicator_clicked', {
        locked: !this.isPublicNonLoginPage,
        unlocked: this.isPublicNonLoginPage,
        user_role_count: this.userRolesWithPageAccess?.length ?? 0,
      });
    },
    onPopoverHide() {
      this.setIsOpen(false);

      if (this.$refs.iconButton) {
        this.$refs.iconButton.active = false;
      }
    },
    setIsOpen(open) {
      this.isOpen = open;
    },
  },
};
</script>

<style>
.kn-popover .popover-inner {
  overflow-y: unset !important;
}

</style>

<style lang="scss" scoped>
.iconButton {
  width: 34px;
  height: 32px;
  border: unset;
  border-radius: 4px;
  background: $white;

  &:hover {
    background: $gray100;
  }

  &_icon {
    height: 18px;
    width: 18px;
    color: $gray700;
  }

  &.active {
    background: $gray700;
  }

  &.active &_icon {
    color: $gray100;
  }
}
</style>
