<template>
  <Modal
    title="Edit Credentials"
    size="medium"
    @close="handleCloseModal"
  >
    <form class="custom-sso-options">
      <!-- static -->
      <div
        v-if="providerSelection === 'sso_google'"
        class="sso-options mb-0 pb-2"
      >
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Client ID</label>
          <input
            v-model="sso_google.clientId"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Client Secret</label>
          <input
            v-model="sso_google.clientSecret"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Restrict by Domain</label>
          <input
            v-model="sso_google.domainRestriction"
            type="text"
          >
        </div>
      </div>
      <!-- custom -->
      <div
        v-if="isCustomProvider"
        class="login-section bottom-divider border-subtle pt-2"
      >
        <h3 class="text-base pb-0 mb-4 font-semibold text-default leading-4 tracking-[0.32px]">
          Login Button
        </h3>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Provider Name <HelpIcon
            copy="Name as it should appear on button. Cannot contain spaces."
            text="Text"
            class="pb-0"
          /></label>
          <input
            v-model="name"
            type="text"
          >
          <p
            v-if="getFormError(`name`)"
            class="error-msg"
          >
            {{ getFormError(`name`) }}
          </p>
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Button Color</label>
          <input
            v-model="buttonColor"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Button Font Color</label>
          <input
            v-model="fontColor"
            type="text"
          >
        </div>
        <div class="pb-0">
          <label class="text-default text-sm font-medium mb-2 leading-4">Button Icon</label>
          <ImageInput
            v-model="logo"
            @update:modelValue="onUpdateImage"
          />
        </div>
      </div>
      <div
        v-if="providerSelection === 'oauth1'"
        class="provider-section pb-0 mb-2"
      >
        <h3 class="text-base pb-0 mb-4 font-semibold text-default leading-4 tracking-[0.32px]">
          Provider Settings
        </h3>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Request URL</label>
          <input
            v-model="oauth1.requestTokenUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Access URL</label>
          <input
            v-model="oauth1.accessTokenUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">User Authorization URL</label>
          <input
            v-model="oauth1.userAuthorizationUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Consumer Key</label>
          <input
            v-model="oauth1.consumerKey"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Consumer Secret</label>
          <input
            v-model="oauth1.consumerSecret"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Profile URL</label>
          <input
            v-model="oauth1.profileUrl"
            type="text"
          >
        </div>
      </div>
      <div
        v-if="providerSelection === 'oauth2'"
        class="provider-section pb-0 mb-2"
      >
        <h3 class="text-base pb-0 mb-4 font-semibold text-default leading-4 tracking-[0.32px]">
          Provider Settings
        </h3>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Authorization URL</label>
          <input
            v-model="oauth2.authorizationUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Access Token URL</label>
          <input
            v-model="oauth2.tokenUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Client ID</label>
          <input
            v-model="oauth2.clientId"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Client Secret</label>
          <input
            v-model="oauth2.clientSecret"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Profile URL</label>
          <input
            v-model="oauth2.profileUrl"
            type="text"
          >
        </div>
      </div>
      <div
        v-if="providerSelection === 'saml'"
        class="provider-section pb-0 mb-2"
      >
        <h3 class="text-base pb-0 mb-4 font-semibold text-default leading-4 tracking-[0.32px]">
          Provider Settings
        </h3>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Provider Entry Point <HelpIcon
            copy="Identity Provider entry point, where the SAML authentication request will be sent."
            text="Text"
            class="pb-0"
          /></label>
          <input
            v-model="saml.entryPoint"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Issuer <HelpIcon
            copy="Name of this Service Provider to supply to the Identity Provider."
            text="Text"
            class="pb-0"
          /></label>
          <input
            v-model="saml.issuer"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Identity Provider's certificate <HelpIcon
            copy="Required Identity Provider certificate, used for validating responses from the Identity Provider."
            text="Text"
            class="pb-0"
          /></label>
          <textarea
            v-model="saml.cert"
            name=""
          />
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Private signing certificate <HelpIcon
            copy="Optional private signing certificate, used to sign requests to the Identity Provider."
            text="Text"
            class="pb-0"
          /></label>
          <textarea
            v-model="saml.privateCert"
            name=""
          />
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Decryption private certificate <HelpIcon
            copy="Optional private key that will be used to attempt to decrypt any encrypted assertions that are received from the Identity Provider."
            text="Text"
            class="pb-0"
          /></label>
          <textarea
            v-model="saml.decryptionPrivateKey"
            name=""
          />
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Decryption public certificate <HelpIcon
            copy="Public certificate matching the decryption private certificate. Required if configured with a decryption private certificate."
            text="Text"
            class="pb-0"
          /></label>
          <textarea
            v-model="saml.decryptionPublicKey"
            name=""
          />
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Logout URL: <HelpIcon
            copy="Optional URL to send a sign out request to when a user ends their Knack session."
            text="Text"
            class="pb-0"
          /></label>
          <input
            v-model="saml.logoutUrl"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Authentication Context <HelpIcon
            copy="Optional Authentication Context class to present to the Identity Provider."
            text="Text"
            class="pb-0"
          /></label>
          <select
            id="sso-custom-saml-option-authentication-context"
            v-model="saml.authenticationContext"
            class="text-base py-2 pl-3 leading-5 ml-0"
            name="sso-custom-saml-option-authentication-context"
          >
            <option
              value=""
            >
              None
            </option>
            <option
              value="urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport"
              selected=""
            >
              urn:oasis:names:tc:SAML:2.0:ac:classes:PasswordProtectedTransport
            </option>
            <option value="urn:oasis:names:tc:SAML:2.0:ac:classes:Password">
              urn:oasis:names:tc:SAML:2.0:ac:classes:Password
            </option>
            <option value="urn:oasis:names:tc:SAML:2.0:ac:classes:TLSClient">
              urn:oasis:names:tc:SAML:2.0:ac:classes:TLSClient
            </option>
            <option value="urn:oasis:names:tc:SAML:2.0:ac:classes:X509">
              urn:oasis:names:tc:SAML:2.0:ac:classes:X509
            </option>
            <option value="urn:federation:authentication:windows">
              urn:federation:authentication:windows
            </option>
            <option value="urn:oasis:names:tc:SAML:2.0:ac:classes:Kerberos">
              urn:oasis:names:tc:SAML:2.0:ac:classes:Kerberos
            </option>
            <option value="urn:oasis:names:tc:SAML:2.0:ac:classes:unspecified">
              urn:oasis:names:tc:SAML:2.0:ac:classes:unspecified
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="providerSelection && isCustomProvider"
        class="pb-0"
      >
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">ID Property</label>
          <input
            v-model="idProperty"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">First Name Property*</label>
          <input
            v-model="firstnameProperty"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Last Name Property*</label>
          <input
            v-model="lastnameProperty"
            type="text"
          >
        </div>
        <div>
          <label class="text-default text-sm font-medium mb-2 leading-4">Email Address Property*</label>
          <input
            v-model="emailaddressProperty"
            type="text"
          >
        </div>
        <p class="instructions mb-0 text-default not-italic text-base">
          *Note: If these fields are left blank, your users will need to enter them manually the first time they log in.
        </p>
      </div>
    </form>

    <ValidationError
      v-if="formErrors.length"
      :errors="formErrors"
    />

    <div
      v-if="providerSelection"
      class="sso-actions flex justify-end gap-4"
    >
      <a
        class="button save margin-right-xxs p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium order-2 h-10 mr-0"
        @click="onClickSave"
      >
        Save
      </a>
      <a
        class="button fuchsia-fill p-3 rounded-lg bg-white border border-solid border-default text-emphasis font-medium m-0 leading-4 order-1 h-10"
        @click="testSSO"
      >
        Test
      </a>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import HelpIcon from '@/components/ui/HelpIcon';
import Modal from '@/components/ui/Modal';
import ValidationError from '@/components/ui/ValidationError';
import ImageInput from '@/components/builder/inputs/Image';
import RequestUtils from '@/components/util/RequestUtils';
import IfPlan from '@/components/util/IfPlan';
import {
  getGoogleValidation,
  getOauth1Validation,
  getOauth2Validation,
  getSamlValidation,
  validateSSOProviderName,
} from '@/lib/sso-helper';
import { validate } from '@/lib/validation-helper';

export default {
  name: 'SSOEdit',
  components: {
    HelpIcon,
    Modal,
    ImageInput,
    IfPlan,
    ValidationError,
  },
  mixins: [
    RequestUtils,
  ],
  props: {
    provider: {
      type: String,
      required: true,
    },
  },
  data() {
    // Not using the instance getter because computeds aren't available yet
    const { app } = this.$store.getters;

    if (!this._isCustomProvider(this.provider)) {
      return {
        sso_google: {
          clientId: get(app, 'settings.sso_google.client_id'),
          clientSecret: get(app, 'settings.sso_google.client_secret'),
          domainRestriction: get(app, 'settings.sso_google.domain_restriction'),
        },
        formErrors: {
          type: Array,
          default: () => [],
        },
      };
    }

    const currentProvider = get(app, `settings.sso.${this.provider}`);

    return {
      name: currentProvider.name,
      buttonColor: currentProvider.button_color,
      fontColor: currentProvider.font_color,
      logo: currentProvider.logo,
      idProperty: currentProvider.id_property, // saml default: eduPersonTargetedID
      firstnameProperty: currentProvider.firstname_property, // saml default: givenName
      lastnameProperty: currentProvider.lastname_property, // saml default: sn
      emailaddressProperty: currentProvider.emailaddress_property, // saml default: mail
      oauth1: {
        accessTokenUrl: currentProvider.access_token_url,
        consumerKey: currentProvider.consumer_key,
        consumerSecret: currentProvider.consumer_secret,
        profileUrl: currentProvider.profile_url,
        requestTokenUrl: currentProvider.request_token_url,
        type: 'oauth1',
        userAuthorizationUrl: currentProvider.user_authorization_url,
      },
      oauth2: {
        authorizationUrl: currentProvider.authorization_url,
        clientId: currentProvider.client_id,
        clientSecret: currentProvider.client_secret,
        profileUrl: currentProvider.profile_url,
        tokenUrl: currentProvider.token_url,
        type: 'oauth2',
      },
      saml: {
        authenticationContext: currentProvider.authentication_context,
        cert: currentProvider.cert,
        decryptionPrivateKey: currentProvider.decryption_private_key,
        decryptionPublicKey: currentProvider.decryption_public_key,
        entryPoint: currentProvider.entry_point,
        issuer: currentProvider.issuer,
        logoutUrl: currentProvider.logouturl,
        privateCert: currentProvider.private_cert,
        type: 'saml',
      },
      formErrors: {
        type: Array,
        default: () => [],
      },
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    appSettings() {
      return this.app.settings;
    },
    isCustomProvider() {
      return this._isCustomProvider(this.providerSelection);
    },

    providerSelection() {
      if (!this._isCustomProvider(this.provider)) {
        return this.provider;
      }

      return get(this.appSettings, `sso.${this.provider}.type`);
    },
  },
  methods: {
    _isCustomProvider(name) {
      return ![
        'sso_google',
      ].includes(name);
    },
    handleCloseModal() {
      this.$router.push(`/pages/${this.$route.params.pageKey}/views/${this.$route.params.viewKey}/login/settings`);
    },
    onUpdateImage(file) {
      if (typeof file === 'string') {
        return;
      }

      this.commitRequest({
        request: () => window.Knack.Api.uploadWebFileForLogo(file.asset, this.name),
        onSuccess: (response) => this.logo = response.logo,
      });
    },
    getFormError(fieldName) {
      if (isEmpty(this.formErrors)) {
        return undefined;
      }

      const foundError = find(this.formErrors, (formError) => formError.flatPath === fieldName);

      return (foundError) ? foundError.message : undefined;
    },
    async onClickSave() {
      if (this.isCustomProvider) {
        return this.saveCustomSSO();
      }

      return this.saveStaticSSO();
    },
    async saveCustomSSO() {
      const { errors: nameErrors } = validateSSOProviderName({
        name: this.name,
      });

      // If the name is different than the original name, make sure it's not already in use
      if (this.provider !== this.name && Object.keys(this.appSettings.sso).includes(this.name)) {
        nameErrors.push(
          {
            message: 'Name is already in use.',
            flatPath: 'name',
          },
        );
      }

      this.formErrors = nameErrors;

      if (!isEmpty(this.formErrors)) {
        return;
      }

      let validation;
      const update = {
        settings: {
          sso: {},
        },
      };

      switch (this.providerSelection) {
        case 'oauth1':
          update.settings.sso[this.name] = {
            name: this.name,
            button_color: this.buttonColor,
            font_color: this.fontColor,
            logo: this.logo,
            access_token_url: this.oauth1.accessTokenUrl,
            consumer_key: this.oauth1.consumerKey,
            consumer_secret: this.oauth1.consumerSecret,
            emailaddress_property: this.emailaddressProperty,
            enabled: true,
            firstname_property: this.firstnameProperty,
            id_property: this.idProperty,
            lastname_property: this.lastnameProperty,
            profile_url: this.oauth1.profileUrl,
            request_token_url: this.oauth1.requestTokenUrl,
            type: 'oauth1',
            user_authorization_url: this.oauth1.userAuthorizationUrl,
          };
          validation = getOauth1Validation();
          break;

        case 'oauth2':
          update.settings.sso[this.name] = {
            name: this.name,
            button_color: this.buttonColor,
            font_color: this.fontColor,
            logo: this.logo,
            authorization_url: this.oauth2.authorizationUrl,
            client_id: this.oauth2.clientId,
            client_secret: this.oauth2.clientSecret,
            emailaddress_property: this.emailaddressProperty,
            enabled: true,
            firstname_property: this.firstnameProperty,
            id_property: this.idProperty,
            lastname_property: this.lastnameProperty,
            profile_url: this.oauth2.profileUrl,
            token_url: this.oauth2.tokenUrl,
            type: 'oauth2',
          };
          validation = getOauth2Validation();
          break;

        case 'saml':
          update.settings.sso[this.name] = {
            name: this.name,
            button_color: this.buttonColor,
            font_color: this.fontColor,
            logo: this.logo,
            authentication_context: this.saml.authenticationContext,
            cert: this.saml.cert,
            decryption_private_key: this.saml.decryptionPrivateKey,
            decryption_public_key: this.saml.decryptionPublicKey,
            emailaddress_property: this.emailaddressProperty,
            enabled: true,
            entry_point: this.saml.entryPoint,
            firstname_property: this.firstnameProperty,
            id_property: this.idProperty,
            issuer: this.saml.issuer,
            lastname_property: this.lastnameProperty,
            logouturl: this.saml.logoutUrl,
            private_cert: this.saml.privateCert,
            type: 'saml',
          };
          validation = getSamlValidation();
          break;
      }

      const { errors: validationErrors } = validate(
        update.settings.sso[this.name],
        validation.validationSchema,
        validation.errorMap,
      );

      this.formErrors = validationErrors;

      if (!isEmpty(this.formErrors)) {
        return;
      }

      // Send the whole sso object to the server to avoid losing any other providers
      const updateMerged = {
        settings: {
          sso: { ..._.cloneDeep(this.appSettings.sso), ..._.cloneDeep(update.settings.sso) },
        },
      };

      this.appSettings.sso = { ...this.appSettings.sso, ..._.cloneDeep(update.settings.sso) };

      if (this.provider !== this.name) {
        // We need to send this property so the server knows its a name change
        updateMerged.settings.sso[this.name].name_changed_from = this.provider;

        // Remove the old provider from the app settings
        delete updateMerged.settings.sso[this.provider];
        delete this.appSettings.sso[this.provider];

        // Keep the enabled/disabled state of the old provider
        const data = {
          ...this.$store.state.activeView.attributes,
          [`sso_custom_${this.provider}`]: undefined,
          [`sso_custom_${this.name}`]: this.$store.state.activeView.attributes[`sso_custom_${this.provider}`],
        };

        await this.saveUpdateView(data);
      }

      const response = await window.Knack.Api.updateApplication(updateMerged);

      this.handleCloseModal();

      return response;
    },
    async saveStaticSSO() {
      let validation;
      const update = {
        settings: {},
      };

      switch (this.providerSelection) {
        case 'sso_google':
          update.settings.sso_google = {
            client_id: this.sso_google.clientId,
            client_secret: this.sso_google.clientSecret,
            domain_restriction: this.sso_google.domainRestriction,
          };

          validation = getGoogleValidation();
          break;
      }

      const { errors: validationErrors } = validate(
        update.settings[this.providerSelection],
        validation.validationSchema,
        validation.errorMap,
      );

      this.formErrors = validationErrors;

      if (!isEmpty(this.formErrors)) {
        return;
      }

      this.appSettings[this.providerSelection] = update.settings[this.providerSelection];

      const response = await this.save(this.appSettings);

      this.handleCloseModal();

      return response;
    },
    async save(update) {
      return new Promise((resolve, reject) => {
        this.commitRequest({
          request: () => this.app.update(update),
          onSuccess: (response) => {
            resolve(response);
          },
          onError: (saveError) => {
            reject(saveError);
          },
        });
      });
    },
    saveUpdateView(data) {
      for (const key of Object.keys(data)) {
        this.$store.state.activeView.attributes[key] = data[key];
      }

      return new Promise((resolve, reject) => {
        this.commitRequest({
          request: () => window.Knack.Api.updateView(
            this.$store.getters.activePage.key,
            this.$store.getters.activeView.key,
            this.$store.state.activeView.attributes,
          ),
          onSuccess: (response) => {
            // This saved all pending updates to the view object, so make sure we turn off the
            // active updates flag.
            this.$store.commit('setViewHasActiveUpdates', false);

            resolve(response);
          },
          onError: (saveError) => {
            reject(saveError);
          },
        });
      });
    },
    doRedirect(event) {
      const staticProviderMap = {
        sso_google: 'google',
      };
      const strategyName = this.isCustomProvider ? this.name : staticProviderMap[this.providerSelection];

      localStorage.setItem('sso-test-strategy-name', strategyName);
      localStorage.setItem('sso-test-page-key', this.$route.params.pageKey);
      localStorage.setItem('sso-test-view-key', this.$route.params.viewKey);

      const returnUrl = window.location.href.split('#')[0];

      this.commitRequest({
        request: () => window.Knack.Api.getSSORedirectURL(strategyName, returnUrl),
        onSuccess: (url) => window.location.assign(url),
      });
    },
    nameToProvider(name) {
      const providersMap = {
        google: 'sso_google',
      };

      if (providersMap[name]) {
        return providersMap[name];
      }

      return this.appSettings.sso[name].type;
    },
    async testSSO() {
      const response = await this.onClickSave();

      if (response) {
        this.doRedirect();
      }
    },
  },
};
</script>

<style lang="scss">
.custom-sso-options {
  margin: 0 auto;

  div {
    padding-bottom: 1em;
  }

  h3 {
    font-weight: bold;
    padding-bottom: 1em;
  }

  textarea {
    min-height: 5.1em
  }

  .bottom-divider {
    margin-bottom: 20px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(56, 57, 60, .125);
  }
}
</style>
