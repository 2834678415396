<template>
  <div v-if="source" class="viewSource grid-verticalGaps pb-0">
    <div v-if="isPageSource">
      <p>This grid is displayed when the user clicks to expand a group from the report on the <strong>{{ parentPageName
          }}</strong> page.</p>
      <p>This means the source will be the records in that report group.</p>
    </div>

    <template v-else>
      <div class="border border-solid border-subtle border-x-0 border-t-0 pb-6">
        <label v-if="!hideLabel"
          class="large underline no-underline text-default text-base font-semibold leading-4 tracking-[0.32px] border-0 tw-toolbox-label">
          Data
        </label>
        <div v-if="object" class="data-source" data-cy="data-source-description">
          <div class="label-description margin-bottom leading-5" v-html="sourceDescription" />

          <template v-if="hasCriteria">
            <div class="text-sm font-medium leading-4 tracking-[0.14px] mt-0.5"
              style="margin-top: .5rem; margin-bottom: .5rem; line-height: 1.2em;">
              Show records that match <select v-model="source.criteria.match" class="text-base py-2 pl-3 leading-5"
                data-cy="source-criteria-match" style="display: inline; width: auto;">
                <option>all</option>
                <option>any</option>
              </select>
            </div>

            <!-- CRITERIA -->
            <FieldList ref="rules" class="is-grouped bg-subtle rounded-lg p-4" style="font-size: .9em;" :items="rules"
              :object-key="sourceObject.key" :logged-in-user-roles="loggedInUserRoles"
              :can-be-empty="groups.length === 0" :rule-type="RuleType.Filter" @update:items="onUpdateRules" />

            <div>
              <template v-for="(group, groupIndex) in groups" :key="`group-${groupIndex}`">
                <div>
                  <div class="my-2 font-medium leading-4 tracking-[0.14px]">
                    {{ matchCopy }}:
                  </div>
                  <FieldList class="is-grouped groupListItem bg-subtle rounded-lg p-4" style="font-size: .9em;"
                    :items="group" :object-key="sourceObject.key" :logged-in-user-roles="loggedInUserRoles"
                    :can-be-empty="true" @delete:item="onDeleteGroupItem"
                    @update:items="onUpdateGroupItems(groupIndex, $event)" />
                </div>
              </template>

              <a v-tippy content="Add groups of rules for more complex matching"
                class="button tiny fuchsia-gray p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 text-base font-medium mt-3 group capitalize"
                @click="onAddGroup">
                <Icon class="text-default h-4 w-4 mr-1 group-hover:text-brand-400" type="plus-thin" />
                Add group
              </a>
            </div>
          </template>

          <div v-else>
            <a class="text-link p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 text-base no-underline font-medium inline-flex items-center"
              data-cy="source-rule-add" @click="onAddRule">
              <Icon class="text-default h-4 w-4 mr-1" type="filter-funnel" />
              Filter Records
            </a>
          </div>
        </div>
      </div>

      <div v-if="showSort" class="border border-solid border-subtle border-x-0 border-t-0 pb-6">
        <label
          class="large underline no-underline text-default text-base font-semibold leading-4 tracking-[0.32px] border-0 tw-toolbox-label">
          Sort
        </label>

        <div class="data-sort">
          <ActionList v-model:items="sortRules" :default-item="defaultSortRule" :can-be-empty="false"
            class="is-grouped bg-subtle rounded-lg p-4">
            <template #default="{ item }">
              <div>
                <select v-model="item.field" class="text-base py-2 pl-3 leading-5">
                  <option v-for="field in objectFields" :key="field.key" :value="field.key">
                    {{ field.name }}
                  </option>
                </select>

                <select v-model="item.order" class="text-base py-2 pl-3 leading-5">
                  <option value="asc">
                    {{ fieldAscending(item.field) }}
                  </option>
                  <option value="desc">
                    {{ fieldDescending(item.field) }}
                  </option>
                </select>
              </div>
            </template>
          </ActionList>
        </div>
      </div>

      <div v-if="showLimit">
        <label
          class="large underline no-underline text-default text-sm font-medium leading-4 tracking-[0.32px] border-0 tw-toolbox-label">
          Limit
        </label>
        <div class="data-limit input-labels tw-input-labels">
          <label><input v-model="limitMode" type="radio" value="all">Use all available records</label>
          <label><input v-model="limitMode" type="radio" value="limit">Use a limited number records</label>
        </div>
        <div v-if="limitMode === 'limit'" class="text-emphasis text-base leading-4 ml-5">
          Use only the first
          <input v-model="source.limit" type="text" class="w-[46px]" style="width: 40px;">
          records
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import ViewUtils from '@/components/views/ViewUtils';
import ActionList from '@/components/ui/lists/ActionList';
import FieldList from '@/components/ui/lists/FieldList';
import Icon from '@/components/ui/Icon';
import FieldOperators from '@/util/FieldOperators';
import { RuleType } from '@/constants/rules';
import { trackIntercomEvent } from '@/lib/intercom-helper';

export default {
  name: 'Source',
  components: {
    ActionList,
    FieldList,
    Icon,
  },
  mixins: [
    ViewUtils,
  ],
  props: {
    backTitle: {
      type: String,
      default: '',
    },
    showSort: {
      type: Boolean,
      default: true,
    },
    showLimit: {
      type: Boolean,
      default: true,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    reportSource: {
      type: Object,
      default: null,
    },
  },
  emits: ['criteriaRulesUpdate', 'save'],
  data() {
    return {
      RuleType,
    };
  },
  computed: {
    ...mapGetters([
      'getObject',
    ]),

    activePage() {
      return this.$store.getters.activePage;
    },
    isPageSource() {
      if (!this.activePage) {
        return false;
      }

      if (!this.activePage.get('source') || !this.activePage.get('source').view) {
        return false;
      }

      return true;
    },
    parentPageName() {
      if (!this.activePage.parent) {
        return '';
      }

      const parentPage = this.$store.getters.getPageBySlug(this.activePage.parent);

      if (!parentPage) {
        return '';
      }

      return parentPage.name;
    },
    calculationMethods() {
      return [
        {
          label: 'sum',
          value: 'sum',
        },
        {
          label: 'average',
          value: 'average',
        },
        {
          label: 'minimum',
          value: 'min',
        },
        {
          label: 'maximum',
          value: 'max',
        },
      ];
    },
    loggedInUserRoles() {
      if (!this.page || !this.page.authenticated) {
        return [];
      }

      return this.page.authentication_profiles.concat('all_users');
    },
    hasCriteria() {
      if (!_.isNil(this.rules) && this.rules.length > 0) {
        return true;
      }

      if (!_.isNil(this.groups) && this.groups.length > 0) {
        return true;
      }

      return false;
    },
    source() {
      if (this.reportSource && this.reportSource.criteria) {
        return this.reportSource;
      }

      if (this.reportSource && !this.reportSource.criteria) {
        this.reportSource.criteria = {
          rules: [],
          groups: [],
        };

        return this.reportSource;
      }

      return this.view?.source;
    },

    sourceObject() {
      if (this.reportSource && this.reportSource.object) {
        return this.getObject(this.reportSource.object);
      }

      return this.object;
    },

    limitMode: {
      get() {
        return this.source.limit ? 'limit' : 'all';
      },
      set(newVar) {
        if (newVar === 'all') {
          this.source.limit = '';
        } else {
          this.source.limit = this.source.limit || 25;
        }
      },
    },
    rules() {
      if (!this.source) {
        return [];
      }

      return this.source.criteria.rules;
    },
    groups() {
      return this.source.criteria.groups;
    },
    matchCopy() {
      return (this.source.criteria.match === 'all') ? 'and match at least one of following' : 'or match all of the following';
    },
    sortRules: {
      get() {
        return this.source.sort;
      },
      set(newSort) {
        this.source.sort = newSort;
      },
    },
    sourceDescription() {
      let sourceDescription = '';
      const viewTypeName = this.viewRaw.type === 'table' ? 'grid' : this.viewRaw.type;

      if (this.reportSource) {
        const sourceKey = this.reportSource.object;
        const knackObject = this.getObject(sourceKey);

        sourceDescription = `This ${viewTypeName} displays <strong>${knackObject.get('inflections').singular}</strong> records`;
      } else {
        sourceDescription = `This ${viewTypeName} displays <strong>${this.object.get('inflections').singular}</strong> records`;
      }

      // no connections
      if (!this.source.connection_key || !this.sourceObject) {
        return `${sourceDescription}.`;
      }

      const directConnection = this.sourceObject.getConnection(this.source.connection_key);

      // broken connection
      if (!directConnection) {
        return `${sourceDescription}.`;
      }

      const directObject = this.$store.getters.getObject(directConnection.object);

      let parentObject;

      // is there a parent connection?
      if (this.source.parent_source) {
        const parentConnection = directObject.getConnection(this.source.parent_source.connection);

        parentObject = this.$store.getters.getObject(parentConnection.object);

        const whichInflection = (parentConnection.belongs_to === 'many') ? 'plural' : 'singular';

        sourceDescription += ` connected to the same <strong style="font-weight: 500;">${directObject.get('inflections')[whichInflection]}</strong>`;
      }

      // The anchor connection is what anchors it to the page: either the page knows about a record or it's the logged-in user
      // This is always the parent object if it exists
      const anchorObject = parentObject || directObject;

      // This view is connected to the logged-in user
      if (this.source.authenticated_user) {
        if (viewTypeName === 'grid') {
          trackIntercomEvent('add_filtered_user_to_grid');
        }
        return `${sourceDescription} connected to the logged-in <strong style="font-weight: 500;">${anchorObject.get('inflections').singular}</strong>.`;
      }

      // This view is connected to this page's record
      return `${sourceDescription} connected to this page's <strong style="font-weight: 500;">${anchorObject.get('inflections').singular}</strong>.`;
    },
    objectFields() {
      return this.sourceObject.fields;
    },
    calculationFields() {
      return [
        {
          name: 'Total number of records',
          key: 'count',
        },
      ].concat(this.objectFields.filter((field) => field.isNumeric()));
    },
    defaultSortField() {
      return get(this, 'objectFields.0.key', null);
    },
  },
  watch: {
    rules: {

      handler(value) {
        this.$emit('criteriaRulesUpdate', value);
      },
      deep: true,
    },
  },
  methods: {
    onDeleteGroupItem() {
      this.source.criteria.groups = this.source.criteria.groups.filter((group) => group.length);
    },

    onUpdateGroupItems(groupIndex, newGroupItems) {
      this.source.criteria.groups[groupIndex] = newGroupItems;
    },

    defaultRule() {
      const field = this.sourceObject.fields[0];

      return {
        field: field.key, operator: FieldOperators.getFirstOperator(field), value: field.getFilterDefault(),
      };
    },

    defaultSortRule() {
      return {
        field: this.defaultSortField, order: 'asc',
      };
    },

    onAddRule() {
      this.source.criteria.rules.push(this.defaultRule());

      this.$emit('save');
    },

    onAddGroup() {
      this.source.criteria.groups.push([
        this.defaultRule(),
      ]);

      this.$emit('save');
    },

    fieldAscending(fieldKey) {
      const field = this.sourceObject.getField(fieldKey);
      if (!field) {
        return 'Invalid field.';
      }

      return field.getSortAscending();
    },

    fieldDescending(fieldKey) {
      const field = this.sourceObject.getField(fieldKey);
      if (!field) {
        return 'Invalid field.';
      }

      return field.getSortDescending();
    },

    onUpdateRules(newRules) {
      if (!this.source?.criteria) {
        return;
      }

      this.source.criteria.rules = newRules;
    },
  },
};
</script>

<style lang="scss" scoped>
.viewSource {
  font-size: .875rem;
}

.data-source b {
  font-weight: 500;
}

.label-description {
  padding-bottom: 4px;
}
</style>
