<template>
  <div
    id="add-page-views"
    class="margin-top"
  >
    <!-- Single Record viewOptions -->
    <ul v-if="recordQuantity === 'many'">
      <li
        v-if="showForms"
        id="page_addForm_menu"
      >
        <label>
          <div class="checkbox checked">
            <input
              ref="form-menu"
              v-model="viewOptions.formMenu"
              type="checkbox"
            >
          </div>A <b>button</b> to add a <em>{{ objectNameSingular }}</em> with a <b><Icon type="form" /> form</b>.
        </label>
      </li>
      <li>
        <div class="checkbox checked">
          <input
            id="multi"
            ref="multi"
            v-model="viewOptions.multiRecords"
            type="checkbox"
          >
        </div>
        <div class="view-label">
          <label for="multi">
            A
          </label>
          <PageAddViewPicker
            ref="multiType"
            v-model:view-type="viewOptions.multiRecordType"
            v-tippy
            content="Select a different view"
            :object-key="pageObject.key"
            class="view-selector"
          />
          <label for="multi">
            to display <em>{{ objectNamePlural }}</em>.
          </label>
        </div>
      </li>
      <ul ref="multiRecords">
        <!-- VIEW ADDONS -->

        <!-- Details and Form -->
        <li style="padding-bottom: 0;">
          <div>Add links for each <em>{{ objectNameSingular }}</em> record to:</div>
        </li>

        <li v-if="showForms">
          <div class="checkbox">
            <input
              id="multiform"
              v-model="viewOptions.multiForm"
              type="checkbox"
            >
          </div>
          <div class="view-label">
            <label
              for="multiform"
              data-cy="child-page-edit"
            >
              Edit with a <b><Icon type="form" /> form</b>.
            </label>
          </div>
        </li>

        <li>
          <div class="checkbox checked">
            <input
              id="multiDetails"
              v-model="viewOptions.multiDetails"
              type="checkbox"
              checked
            >
          </div>
          <div class="view-label">
            <label
              for="multiDetails"
              data-cy="child-page-details"
            >
              View more <b><Icon type="details" /> details</b>.
            </label>
          </div>
        </li>
        <ul
          v-if="connectedObjects.length"
          ref="multiDetailAddonViews"
          style="overflow: hidden; max-height: 0px;"
        >
          <li style="border: 0; padding: 0; padding-bottom: .75em;">
            <a
              id="add-detail-views"
              @click="onAddDetailsView"
            >
              <Icon type="add-circle-outline" /> Add connected views to this details page
            </a>
          </li>

          <ActionList
            v-model:items="viewOptions.multiDetailAddonViews"
            :can-be-empty="true"
            :default-item="defaultDetailsView"
            class="naked addon-views"
          >
            <template #default="props">
              <div>
                <Dropdown
                  v-model="props.item.connObject"
                  :options="connectedObjects"
                  class="is-text"
                />
                <span style="margin: 0 .35em; white-space: nowrap;">
                  with a
                </span>
                <PageAddViewPicker
                  v-model:view-type="props.item.viewType"
                  v-tippy
                  content="Select a different view"
                  :object-key="props.item.connObject"
                  class="view-selector"
                />
              </div>
            </template>
          </ActionList>
        </ul>
      </ul>
      <li
        v-if="showForms"
        id="page_addForm"
        class="off"
      >
        <label>
          <div class="checkbox">
            <input
              ref="form-add"
              v-model="viewOptions.formAdd"
              type="checkbox"
            >
          </div>A <b><Icon type="form" /> <span>form</span></b> to add a new <em>{{ objectNameSingular }}</em>.
        </label>
      </li>
    </ul>

    <!-- Single Record viewOptions -->
    <ul
      v-if="recordQuantity === 'one'"
      id="quickstart-views-single"
      class="add-page-views criteria"
    >
      <li v-if="showForms">
        <label>
          <div class="checkbox">
            <input
              ref="form-edit"
              v-model="viewOptions.formEdit"
              type="checkbox"
            >
          </div>A <b><Icon type="form" /> <span>form</span></b> to edit the <em>{{ objectNameSingular }}</em>.
        </label>
      </li>
      <li>
        <label>
          <div class="checkbox">
            <input
              ref="details"
              v-model="viewOptions.details"
              type="checkbox"
            >
          </div><b><Icon type="details" /> <span>details</span></b> to view the <em>{{ objectNameSingular }}</em>.
        </label>
      </li>
      <ul
        v-if="connectedObjects.length"
        ref="detailAddonViews"
        style="overflow: hidden; max-height: 0px;"
      >
        <li style="border: 0; padding: 0; padding-bottom: .75em;">
          <a
            id="add-detail-views"
            @click="onAddDetailsView"
          >
            <Icon type="add-circle-outline" /> Add connected views to this details page
          </a>
        </li>

        <ActionList
          v-model:items="viewOptions.multiDetailAddonViews"
          :can-be-empty="true"
          :default-item="defaultDetailsView"
          class="naked"
        >
          <template #default="props">
            <div>
              <Dropdown
                v-model="props.item.connObject"
                :options="connectedObjects"
                class="is-text"
              />
              <span style="margin: 0 .35em; white-space: nowrap;">
                with a
              </span>
              <PageAddViewPicker
                v-model:view-type="props.item.viewType"
                v-tippy
                content="Select a different view"
                :object-key="props.item.connObject"
                class="view-selector"
              />
            </div>
          </template>
        </ActionList>
      </ul>
    </ul>

    <div style="margin-top: 1.25em;">
      <a
        class="button orange-fill small"
        @click="onSubmit()"
      >
        Continue
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageAddViewPicker from '@/components/pages/PageAddViewPicker';
import Icon from '@/components/ui/Icon';
import PageAddUtils from '@/components/pages/PageAddUtils.js';
import UIMixin from '@/components/ui/UIMixin.js';
import ActionList from '@/components/ui/lists/ActionList';
import Dropdown from '@/components/ui/Dropdown';
import { NEW_VIEW_KEY } from '@/lib/page/page-constants';
import { trackIntercomEvent } from '@/lib/intercom-helper';

export default {
  components: {
    Icon,
    PageAddViewPicker,
    ActionList,
    Dropdown,
  },
  mixins: [
    PageAddUtils,
    UIMixin,
  ],
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    page: {
      type: Object,
      default: () => {},
    },
  },
  emits: [
    'submit',
  ],
  data() {
    return {

      viewOptions: {
        formMenu: true,
        formAdd: false,
        formEdit: false,
        details: false,
        multiRecords: true,
        multiRecordType: 'table',
        multiForm: false,
        multiDetails: false,
        multiDetailAddonViews: [],
      },
      views: [],
    };
  },
  computed: {
    ...mapGetters([
      'getObject',
    ]),
    pageObject() {
      return this.getObject(this.options.source.object);
    },
    showForms() {
      // ecommerce objects are read only
      return !this.pageObject.get('ecommerce');
    },
    objectNameSingular() {
      return this.pageObject.inflections.singular;
    },
    objectNamePlural() {
      return this.pageObject.inflections.plural;
    },
    recordQuantity() {
      let which = 'many';

      if (this.options.recordQuantity) {
        which = this.options.recordQuantity;
      } else if (this.options.isSinglePage && this.page.get('object')) {
        which = 'one';
      }

      return which;
    },
    connectedObjects() {
      const conns = [];

      this.pageObject.get('connections').inbound.forEach((conn) => {
        if (conn.belongs_to !== 'many') {
          return;
        }

        conns.push({
          key: conn.key,
          objectKey: conn.object,
          value: conn.object,
          label: `show connected <b>${this.getConnObject(conn).inflections.plural}</b>`,
        });

        conns.push({
          key: conn.key,
          objectKey: conn.object,
          value: conn.object,
          label: `add a connected <b>${this.getConnObject(conn).inflections.singular}</b>`,
        });
      });

      log('connectedObjects', conns);

      return conns;
    },
  },
  watch: {
    'options.source.object': function (newVar) {
      log('= = = =WATCH source object!!!');

      // reset any additional addon views since these are object dependent
      this.viewOptions.multiDetails = false;
      this.viewOptions.multiDetailAddonViews = [];
    },
    'viewOptions.multiRecords': function (newVar) {
      this.$nextTick(() => {
        this.slideToggle(this.$refs.multiRecords, newVar);
      });
    },
    'viewOptions.formMenu': function (newVar) {
      if (newVar) {
        this.viewOptions.formAdd = false;
      }
    },
    'viewOptions.formAdd': function (newVar) {
      if (newVar) {
        this.viewOptions.formMenu = false;
      }
    },
    'viewOptions.multiDetails': function (newVar) {
      if (!this.$refs.multiDetailAddonViews) {
        return;
      }

      this.$nextTick(() => {
        this.slideToggle(this.$refs.multiDetailAddonViews, newVar, {

          visibility: 'visible',
        });
      });
    },
    'viewOptions.details': function (newVar) {
      if (!this.$refs.detailAddonViews) {
        return;
      }

      this.$nextTick(() => {
        this.slideToggle(this.$refs.detailAddonViews, newVar, {

          visibility: 'visible',
        });
      });
    },
    viewOptions: {

      handler() {
        this.buildNewPageModel();
      },
      deep: true,
    },
  },
  created() {
    log('created!!!');

    this.buildNewPageModel();
  },
  methods: {
    async buildNewPageModel() {
      log('buildNewPageModel');

      this.page.clearLocalViews();

      const views = this.buildPageViews();

      log('views', views, 'this.page', this.page);

      for (const view of views) {
        view.key = NEW_VIEW_KEY;

        await this.$store.dispatch('page/view/addViewLocally', {
          addPage: this.page,
          viewData: view,
          locationType: 'group',
          groupIndex: -1, // This (-1) will push the view onto the end.
          batchMode: true,
        });
      }
    },
    defaultDetailsView() {
      return {
        connObject: this.connectedObjects[0].objectKey,
        viewType: 'table',
      };
    },
    onAddDetailsView() {
      this.viewOptions.multiDetailAddonViews.push(this.defaultDetailsView());
    },
    onSubmit() {
      return this.$emit('submit', this.buildPageViews());
    },
    buildPageViews() {
      const pageOptions = [];

      const page = this.setPageSchemaFromWizardOptions(this.options);

      let formView;

      // Add Forms
      if (this.viewOptions.formMenu || this.viewOptions.formAdd) {
        formView = this.buildFormView(page);

        // Form linked from a menu view
        if (this.viewOptions.formMenu) {
          pageOptions.push(this.buildFormMenuView(page, formView));
        }

        trackIntercomEvent('add_view_form');
      }

      // Edit Form
      if (this.viewOptions.formEdit) {
        const view = {
          name: `Edit ${this.objectNameSingular}`,
          type: 'form',
          action: 'update',
          source: this.options.source,
        };

        pageOptions.push(this.buildViewSchema(page, view));
      }

      if (this.viewOptions.multiRecords) {
        const multiRecordView = this.buildRecordsView(page);

        if (multiRecordView) {
          pageOptions.push(multiRecordView);
        }

        // List view
        if (this.viewOptions.multiDetails) {
        }
      }

      // Place any insert forms at the bottom of the page
      if (this.viewOptions.formAdd) {
        pageOptions.push(formView);
      }

      return pageOptions;
    },
    buildFormView(page) {
      const view = {
        name: `Add ${this.objectNameSingular}`,
        type: 'form',
        action: 'insert',
      };

      // add source for connected objects
      if (this.options.source) {
        view.source = this.options.source;
      } else {
        view.source = {
          object: this.pageObject.key,
        };
      }

      return this.buildViewSchema(page, view);
    },
    buildFormMenuView(page, formView) {
      const newPage = {
        name: formView.name,
        viewOptions: [
          formView,
        ],
      };

      // we may need an object on this page if it`s coming from a details page
      if (this.options.isSinglePage && this.page.object) {
        newPage.object = this.page.object;
      }

      const pageToAdd = {
        type: 'form',
        page: newPage,
      };

      const menu = {
        name: 'Menu',
        type: 'menu',
        source: {},
      };

      const viewOptions = {
        pagesToAdd: [
          pageToAdd,
        ],
      };

      return this.buildViewSchema(page, menu, viewOptions);
    },
    buildRecordsView(page) {
      log('buildRecordsView()', page, this.viewOptions.multiRecordType);

      // create records view
      const recordsView = {
        name: this.objectNamePlural,
        type: this.viewOptions.multiRecordType,
        source: this.options.source || {
          object: this.pageObject.key,
        },
      };

      // checked options (like edit/details/connected views) to add to this view
      const viewOptions = {
        pagesToAdd: [],
      };

      // add a link to an edit form to this view
      if (this.viewOptions.multiForm) {
        const pageName = `Edit ${this.objectNameSingular}`;

        const page = {
          name: pageName,
          object: this.pageObject.get('key'),
        };

        const views = [
          {
            name: pageName,
            type: 'form',
            action: 'update',
          },
        ];
        const formPage = {
          type: 'form',
          page: this.buildPageModel(page, views),
        };

        log('adding formPage', formPage, views);
        viewOptions.pagesToAdd.push(formPage);
      }

      // add a link to a details form to this view
      if (this.viewOptions.multiDetails) {
        // start the details views
        const pageName = `${this.objectNameSingular} Details`;
        const views = [
          {
            name: pageName,
            type: 'details',
          },
        ];

        // add any detail addon views
        if (this.viewOptions.multiDetailAddonViews) {
          this.viewOptions.multiDetailAddonViews.forEach((addonView) => {

          });
        }

        // add the details page
        const page = {
          name: pageName,
          object: this.pageObject.key,
        };
        const detailsPage = {
          type: 'details',
          page: this.buildPageModel(page, views),
        };

        log('adding detailsPage', detailsPage, views);
        viewOptions.pagesToAdd.push(detailsPage);
      }

      log(`buildRecordsView() type: ${this.viewOptions.multiRecordType}`, viewOptions);

      return this.buildViewSchema(page, recordsView, viewOptions);
    },
    getConnObject(conn) {
      return this.getObject(conn.object);
    },
  },
};
</script>

<style lang="scss">
#add-page-views {

  font-size: .95em;

  .checkbox {
    // background-color: rgb(240, 242, 245);
    // line-height: 1em;
    margin-right: 4px;
    display: inline-block;
    cursor: pointer;
    padding: 0 5px;
  }

  .view-label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .view-selector {
    display: inline-block;
    position: relative;
    cursor: pointer;
    line-height: 1em;
    padding: 0 .2em .2em;
    margin: 0px 3px;
    border-bottom: 1px solid #a8abb3;
    white-space: nowrap;

    &:hover {
      background: rgba(141, 144, 152, 0.15);
      border-bottom: 1px solid #8a337a;
    }
  }

  label {
    line-height: 1em;
  }
  > ul > li {
    // background-color: rgb(231, 233, 236);
    // padding: 8px;
    // border-top: 1px solid #e7e9ec;

  }

  > ul > ul, > ul > ul > ul {
    margin-left: 10px;
    padding-left: 1em;
    border-left: 1px solid #dddee2;
  }
  ul {
    transition: all 300ms ease-out;
    overflow: hidden;
  }
  li {
    padding: .65em 0;
    display: flex;

    &:last-child {
      margin-bottom: .25em;
    }
    em {
      font-weight: 500;
      font-style: normal;
      color: #000;
    }
  }

  .icon {
    width: 18px;
    height: 18px;
    position: relative;
    right: -1px;
    top: 4px;

    &.icon-dropdown {
      width: 11px;
      height: 11px;
      top: 1px;
      margin-left: -1px;
      color: $fuchsia-text;
    }
  }

  input[type=checkbox] {
    cursor: pointer;
    margin: 0;
    line-height: 1em;
    font-size: 1em;
  }

  .action-list li {
    padding: .35em .5em .5em .2em;
    background: #e7e8ec;
  }

  .addon-views .add-link-button {
    display: none;
  }
}
</style>
