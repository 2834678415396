<template>
  <div
    :style="{ animationDelay: `${animationDelay}ms !important`, opacity: 0 }"
    class="flex w-full animate-slideDownAndFade rounded-lg bg-muted p-2 fill-mode-forwards min-h-[72px] items-center gap-3 px-4"
  >
    <Icon
      :type="icon"
      class="fill-[url(#svg-gradient-2)] min-h-8 min-w-8"
    />
    <div class="flex flex-col gap-1 pb-1 pt-0.5">
      <div class="text-md">
        <span class="font-medium text-emphasis">
          {{ title }}:
        </span>
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon.vue';

export default {
  name: 'AiInfoItem',
  components: { Icon },
  props: {
    animationDelay: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
  },
};
</script>
