<template>
  <router-link
    :to="href"
    class="w-full"
  >
    <button
      class="text-sm font-semibold cursor-pointer inline-flex items-center justify-center appearance-none focus:outline-none focus:outline-default focus-visible:ring-2 focus-visible:ring-gray-400 disabled:opacity-50 disabled:pointer-events-none bg-action-gradient text-white transition-all duration-400 hover:bg-animation h-10 p-3 rounded-lg w-full animate-slideDownAndFade fill-mode-forwards border-none"
      :style="{ animationDelay: `${animationDelay}ms !important`, opacity: 0 }"
    >
      <slot />
    </button>
  </router-link>
</template>

<script>
export default {
  name: 'WelcomeLink',
  props: {
    animationDelay: {
      type: Number,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
  },
};

</script>
