<template>
  <canvas
    v-if="animationActive"
    ref="canvasRef"
    class="pointer-events-none absolute inset-0 z-10"
  />
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'ConfettiAnimation',
  setup() {
    const canvasRef = ref(null);
    const animationActive = ref(true);

    const animateConfetti = (canvas, ctx, confetti) => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      let particlesRemaining = 0;

      confetti.forEach((particle) => {
        particle.x += particle.speedX;
        particle.y += particle.speedY;
        particle.rotation += particle.speedX;

        ctx.save();
        ctx.translate(particle.x, particle.y);
        ctx.rotate((particle.rotation * Math.PI) / 180);
        ctx.fillStyle = particle.color;
        ctx.fillRect(
          -particle.size / 2,
          -particle.size / 2,
          particle.size,
          particle.size,
        );
        ctx.restore();

        if (particle.y <= canvas.height) {
          particlesRemaining += 1;
        }
      });

      if (particlesRemaining > 0) {
        requestAnimationFrame(() => animateConfetti(canvas, ctx, confetti));
      } else {
        animationActive.value = false;
      }
    };

    onMounted(() => {
      const canvas = canvasRef.value;
      if (!canvas) return;

      const ctx = canvas.getContext('2d');

      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      const colors = ['#FF007A', '#7A00FF', '#00FF7A', '#FFD700', '#00D4FF'];

      const confetti = Array.from({ length: 200 }, () => ({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height - canvas.height,
        size: Math.random() * 10 + 5,
        color: colors[Math.floor(Math.random() * colors.length)],
        speedX: Math.random() * 6 - 3,
        speedY: Math.random() * 12 + 4,
        rotation: Math.random() * 360,
      }));

      animateConfetti(canvas, ctx, confetti);
    });

    return {
      canvasRef,
      animationActive,
    };
  },
};
</script>
