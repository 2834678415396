import Joi from '@hapi/joi';
import { Helpers } from '@knack/core/dist/helpers';

import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import hasIn from 'lodash/hasIn';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import moment from 'moment-timezone';

import { NEW_VIEW_KEY } from '@/lib/page/page-constants';
import {
  defaultCharacterLimit,
  defaultReport,
  getDefaultSubmitRuleSchema,
  isCharacterLimitValid,
} from '@/lib/schema-helper';
import { validate } from '@/lib/validation-helper';
import store from '@/store';
import SchemaUtils from '@/store/utils/SchemaUtils';

const { SampleHelper } = Helpers;

class View {
  constructor(view, page) {
    this.pageKey = page.key;
    this.sourceOptions = page.sourceOptions;

    if (view instanceof View) {
      return view;
    }

    this.setMeta(view);

    // set attributes
    this.setView(view);

    // properties for handling canceable edits
    this.attributesRestore = {};

    this.data = {
      type: 'multiple',
      records: [],
      isLoaded: false,
    };
  }

  setView(viewAttributes) {
    // The following setDefaults() call will eventually reference this.attributes,
    // so make sure it is defined.
    this.attributes = {};

    // First set defaults
    const viewSchema = { ...this.setDefaults(viewAttributes), ...viewAttributes };

    // set attributes first so setSchema has access to some basics
    this.attributes = viewSchema;

    // Make any post default adjustments
    this.attributes = this.setSchema(viewSchema);

    // Ensure no empty groups, fields exist, etc.
    this.sanitizeSchema();
  }

  validate() {
    // Create a custom stringArray() joi format that treats comma separated values as an array.
    const customEmailJoi = ((joi) => ({
      base: joi.array(),
      name: 'stringArray',
      coerce: (value, state, options) => (value.split ? value.split(',') : value), // eslint-disable-line no-unused-vars
    }));

    const fromEmailRule = Joi.string()
      .email()
      .label('fromEmail')
      .required();

    const recipientEmailRule = Joi.string()
      .email()
      .label('recipientEmail');

    const recipientEmailsRule = Joi.any()
      .when('recipient_type', {
        is: 'custom',
        then: Joi.extend(customEmailJoi)
          .stringArray()
          .items(recipientEmailRule)
          .single()
          .required(),
      });

    // Action Links
    const recordRulesValidationSchema = Joi.object({
      criteria: Joi.array().allow(null),
      action: Joi.string(),
      values: Joi.array().optional(),
      email: Joi.object().optional().when('action', {
        is: 'email',
        then: Joi.object({
          from_email: fromEmailRule,
          from_name: Joi.string().empty('').optional(),
          message: Joi.string().empty('').optional(),
          recipients: Joi.array().items(Joi.object().keys({
            email: recipientEmailsRule,
            recipient_mode: Joi.string(),
            recipient_type: Joi.string(),
          })),
          subject: Joi.string().empty('').optional(),
        }).required(),
      }),
    });

    const emailRulesValidationSchema = Joi.object().keys({
      email: Joi.object().keys({
        recipients: Joi.array().items(Joi.object().keys({
          email: recipientEmailsRule,
        })),
      }).when('action', {
        is: 'email',
        then: Joi.object({
          from_email: fromEmailRule,
        }),
      }),
    });

    const actionRulesValidationSchema = Joi.object().keys({
      record_rules: Joi
        .array()
        .items(recordRulesValidationSchema)
        .min(1)
        .required(),
    });

    const actionLinksValidationSchema = Joi.object().keys({
      action_rules: Joi.array().items(actionRulesValidationSchema),
      edit_rules: Joi.array().items(recordRulesValidationSchema).min(0).optional(),
    });

    // Links
    const linksValidationSchema = Joi.object().keys({
      icon: Joi.object(),
      name: Joi.string().required(),
      scene: [
        Joi.object(), // link to a new page
        Joi.string(), // link to an existing page
      ],
      type: Joi.string(),
    });

    const validationSchema = Joi.object().keys({
      _id: Joi.string(),
      name: Joi.string().required(),
      key: Joi.string().empty('').optional(),
      groups: Joi.array(),
      // rich text view content, may be an empty string
      content: Joi.string().empty('').optional(),
      rows: Joi.optional(),
      details: Joi.optional(),
      design: Joi.object(),
      no_data_text: Joi.string().empty('').optional(),
      totals: Joi.array(),
      preset_filters: Joi.array(),
      filters_custom: Joi.boolean(),
      filters_menu: Joi.boolean(),
      type: Joi.string().required(),
      source: Joi.object(),
      title: Joi.string().empty('').optional(),
      description: Joi.string().empty('').optional(),
      rows_per_page: Joi.number(),
      keyword_search: Joi.boolean(),
      keyword_search_fields: Joi.string().empty('').optional(),
      allow_exporting: Joi.boolean(),
      allow_preset_filters: Joi.boolean(),
      filter_type: Joi.string(),
      display_pagination_below: Joi.boolean(),
      label: Joi.string(),
      inputs: Joi.array(),
      links: Joi.array().items(linksValidationSchema),
      columns: Joi.array().items(actionLinksValidationSchema),
      reportType: Joi.optional(),
      rules: Joi.object().keys({
        emails: Joi.array().items(emailRulesValidationSchema),
      }),
    });

    const fromEmailMap = (source) => ({
      label: 'From Email',
      types: {
        'string.email': `The "From" email address provided in ${source} is invalid.`,
        'array.includesRequiredKnowns': `Please provide a valid "From" email address in ${source}.`,
        'any.empty': `Please provide a valid "From" email address in ${source}.`,
      },
    });
    const recipientEmailMap = (source) => ({
      label: 'Recipients Email',
      types: {
        'string.email': `The "Recipient" email address provided in ${source} is invalid.`,
        'array.includesRequiredKnowns': `Please provide a valid "Recipient" email address in ${source}.`,
        'any.empty': `Please provide a valid "From" email address in ${source}.`,
      },
    });

    const errorMap = {
      // Action rules/links email validation.
      'columns.action_rules.record_rules.email.from_email': fromEmailMap('action links'),
      'columns.action_rules.record_rules.email.recipients.email': recipientEmailMap('action links'),

      // Record rules email validation.
      'columns.edit_rules.email.from_email': fromEmailMap('record rules'),
      'columns.edit_rules.email.recipients.email': recipientEmailMap('record rules'),

      // Email rules email validation.
      'rules.emails.email.from_email': fromEmailMap('email rules'),
      'rules.emails.email.recipients.email': recipientEmailMap('email rules'),
    };

    return validate(this.attributes, validationSchema, errorMap);
  }

  get name() {
    return this.attributes.name;
  }

  set name(name) {
    this.attributes.name = name;
  }

  get key() {
    return this.attributes.key;
  }

  set key(key) {
    this.attributes.key = key;
  }

  // We currently alias registration types to form types for convenience, since they are 98% treated identically.
  // This function checks if the form is indeed a registration form.
  isRegistrationForm() {
    return this.attributes.type === 'registration';
  }

  get type() {
    // For all intents and purposes a registration view is treated as a form.
    // Currently there's no code that needs to distinguish registration views, but if that changes this approach may need new consideration
    if (this.attributes.type === 'registration') {
      return 'form';
    }

    return this.attributes.type;
  }

  set type(type) {
    this.attributes.type = type;
  }

  get design() {
    return this.attributes.design;
  }

  set design(design) {
    this.attributes.design = design;
  }

  get rows() {
    return this.attributes.rows;
  }

  set rows(rows) {
    this.attributes.rows = rows;
  }

  get source() {
    return this.attributes.source;
  }

  set source(source) {
    this.attributes.source = source;
  }

  get reports() {
    return this.attributes.reports;
  }

  set reports(reports) {
    this.attributes.reports = reports;
  }

  get parent() {
    return this.attributes.parent;
  }

  get groups() {
    return this.attributes.groups;
  }

  set groups(groups) {
    this.attributes.groups = groups;
  }

  get links() {
    return this.attributes.links;
  }

  set links(links) {
    this.attributes.links = links;
  }

  get paymentProcessors() {
    return this.attributes.payment_processors;
  }

  set paymentProcessors(paymentProcessors) {
    this.attributes.payment_processors = paymentProcessors;
  }

  get summaryFields() {
    return this.attributes.summary_fields;
  }

  set summaryFields(summaryFields) {
    this.attributes.summary_fields = summaryFields;
  }

  get customerOptional() {
    return this.attributes.customer_optional;
  }

  set customerOptional(customerOptional) {
    this.attributes.customer_optional = customerOptional;
  }

  get chargeCustomer() {
    return this.attributes.charge_customer;
  }

  set chargeCustomer(chargeCustomer) {
    this.attributes.charge_customer = chargeCustomer;
  }

  get loggedInUser() {
    return this.attributes.logged_in_user;
  }

  set loggedInUser(loggedInUser) {
    this.attributes.logged_in_user = loggedInUser;
  }

  get firstConnection() {
    return this.attributes.first_connection;
  }

  set firstConnection(firstConnection) {
    this.attributes.first_connection = firstConnection;
  }

  get columns() {
    return this.attributes.columns;
  }

  set columns(newColumns) {
    this.attributes.columns = newColumns || [];
  }

  isUpdateForm() {
    return (this.type === 'form' && this.get('action') === 'update');
  }

  isInsertForm() {
    return (this.type === 'form' && this.get('action') !== 'update');
  }

  worksWithSpecificRecord() {
    if (this.type === 'details') {
      return true;
    }

    // Checkouts must work with a single record
    if (this.isCheckoutType()) {
      return true;
    }

    // Insert forms don't know about a specific record yet
    if (this.isUpdateForm()) {
      return true;
    }

    // Everything else either works with many records (e.g. table)
    // Or is a form inserting a new record
    return false;
  }

  /**
   * Gets all the objects that have the potential to connect to this view.
   *
   * @returns {object} potentialSources
   */
  getAllPotentialConnectedSources() {
    const sourceObject = store.getters.getObject(this.source.object);

    // Record type is if this view knows about one specific record
    const sourceRecordType = (this.worksWithSpecificRecord()) ? 'one' : 'many';

    // potentialSources is an object of type arrays indexed by objectKeys
    const potentialSources = {};

    // Function to add objects as potential source
    const addPotentialSource = function (objectKey, recordType) {
      if (!potentialSources[objectKey]) {
        potentialSources[objectKey] = [];
      }

      // Not sure if we'll need these later yet
      if (!potentialSources[objectKey].includes(recordType)) {
        potentialSources[objectKey].push(recordType);
      }
    };

    // Add the view's object as the first potential source
    addPotentialSource(sourceObject.key, sourceRecordType);

    // Add direct connections to parents
    // These are always connections to just one parent
    sourceObject.conns.forEach((conn) => {
      const connObject = store.getters.getObject(conn.object);

      // We don't follow e-commerce connections at all
      if (connObject.ecommerce || connObject.ecommercePaymentMethods) {
        return;
      }

      if (conn.belongs_to === sourceRecordType) {
        addPotentialSource(conn.object, sourceRecordType);

        // Add secondary connections to grandparents
        // The parent connection follows sourceRecordType
        // The grandparent connections are always to just one
        // Example: employees connected to the company connected to the logged-in boss
        // Example: projects connected to employees connected to a specific company

        // Check for grandparents
        connObject.conns.forEach((subConn) => {
          if (subConn.belongs_to === 'one') {
            addPotentialSource(subConn.object, 'one');
          }
        });
      }
    });

    return potentialSources;
  }

  getCreateLocation() {
    if (!this.insertLocation) {
      return {};
    }

    return this.insertLocation;
  }

  setCreateLocation(locationType, groupIndex, columnIndex, itemIndex) {
    this.insertLocation = {
      locationType,
      groupIndex,
      columnIndex,
      itemIndex,
    };
  }

  setDetailsItemSchema(viewLabelFormat, columns) {
    if (viewLabelFormat) {
      // viewLabelFormat could be coming from other V2 schema locations, so this avoids circular object references
      viewLabelFormat = cloneDeep(viewLabelFormat);
    }

    if (!columns) {
      return [];
    }

    return columns.map((column) => {
      column.groups = column.groups?.map((group) => {
        group.columns = group.columns?.map((column2) => {
          column2 = column2.map((item) => {
            item = { ...SchemaUtils.detailDefaults(), ...item };

            // Set the label_custom property if not defined
            if (typeof item.format.label_custom === 'undefined') {
              let customLabel = true;

              if (!item.format.label_format || item.format.label_format === 'default') {
                customLabel = false;
              }

              item.format.label_custom = customLabel;
            }

            // Convert v2 defaults to custom if the group default is different than the view default
            if (group.label_format && !item.format.label_custom && group.label_format !== viewLabelFormat) {
              item.format.label_custom = true;
              item.format.label_format = group.label_format;
            }

            if (!item.format.styles) {
              item.format.styles = [];
            }

            return item;
          });

          return column2;
        });

        // Delete the group label format so the v2 correction doesn't continue to run
        delete group.label_format;

        return group;
      });

      return column;
    });
  }

  setSchemaFilters(filters = {}) {
    if (!filters.filter_type) {
      filters.filter_type = 'none';
    }

    if (!filters.allow_preset_filters) {
      filters.preset_filters = [];
    }

    if (!filters.menu_filters) {
      filters.menu_filters = [];
    }

    if (!filters.filter_fields) {
      filters.filter_fields = 'view';
    }

    /*
    // new filter split, accomodate both custom OR menu filters
    if (typeof filters.filters_custom === `undefined`) {

      filters.filters_custom = (filters.filter_type === `fields`)
    }

    if (typeof filters.filters_menu === `undefined`) {

      filters.filters_menu = (filters.filter_type === `menu`)
    }

    if (filters.menu_filters) {

      // convert filters to accomodate more than 1
      filters.menu_filters = filters.menu_filters.map((filter) => {

        if (filter.criteria) {

          return filter
        }

        const text = filter.text

        delete filter.text

        return {
          text,
          criteria: [
            filter
          ]
        }
      })
    }
    */

    return filters;
  }

  defaultReportOptions() {
    return {
      exclude_empties: false,
      hide_negatives: false,
      child_records: false,
      export_links: false,
      shouldShowDataTable: false,
    };
  }

  defaultReportSettings() {
    return {
      export_links: false,
      shouldShowDataTable: false,
    };
  }

  defaultReportFilters() {
    return this.setSchemaFilters();
  }

  defaultReportLayout(report) {
    return {
      dimensions: 'auto',
      chart_width: '500',
      chart_height: '350',
      legend_width: '170',
      legend: (report.type === 'pie') ? 'right' : 'bottom',
    };
  }

  setSchema(view) {
    if (!view) {
      return;
    }

    if (!view.name) {
      view.name = view.title || view.type;
    }

    // correct criteria = [] sources
    if (view.source) {
      view.source = { ...this.defaultSource(), ...view.source };

      if (Array.isArray(view.source.criteria) && view.source.criteria.length === 0) {
        view.source.criteria = this.defaultSource().criteria;
      }

      // Convert rules that for some reason store dates in a .date property rather than .value`
      if (view.source.criteria.rules) {
        view.source.criteria.rules = view.source.criteria.rules.map((rule) => {
          if (rule.date && !rule.value) {
            rule.value = {
              date: rule.date,
            };

            delete rule.date;
          }

          return rule;
        });
      }

      if (isEmpty(view.source.sort)) {
        view.source.sort = this.defaultSourceSort();
      }

      // some sorts from old schemas can have null values for sort properties
      view.source.sort = view.source.sort.map((source) => {
        if (!source.field) {
          return this.defaultSourceSort()[0];
        }

        return source;
      });
    }

    // If view is new, we don't need to configure anything further

    // Filters
    if (view.filter_type) {
      this.setSchemaFilters(view);
    }

    switch (view.type) {
      case 'calendar':

        // event defaults
        const eventDefaults = {
          event_colors: [],
          display_type: 'calendar',
          view: 'agendaWeek',
          week_start: 'sunday',
        };

        view.events = { ...eventDefaults, ...view.events };

        if (!view.details) {
          view.details = {
            columns: [],
          };
        }

        if (!view.details.label_format) {
          view.details.label_format = (view.details.columns[0] && view.details.columns[0].groups[0]) ? view.details.columns[0].groups[0].label_format : 'left';
        }

        view.details.columns = this.setDetailsItemSchema(view.details.label_format, view.details.columns);

        this.setSchemaFilters(view);

        return view;

      case 'checkout':

        // Ensure a default submit rule exists
        view = this.verifySubmitRules(view);

        if (view.checkout_page) {
          if (!view.checkout_page.label_format) {
            view.checkout_page.label_format = (view.checkout_page.columns?.[0]?.groups?.[0])
              ? view.checkout_page.columns[0].groups[0].label_format
              : 'left';
          }

          view.checkout_page.columns = this.setDetailsItemSchema(
            view.checkout_page.label_format,
            view.checkout_page.columns,
          );
        }

        return view;

      case 'details':

        if (!view.rules || !view.rules.fields) {
          view.rules = {
            fields: [],
          };
        }

        if (!hasIn(view, 'label_format') || view.label_format === false) {
          view.label_format = (view.columns[0] && view.columns[0].groups[0]) ? view.columns[0].groups[0].label_format : 'left';
        }

        view.columns = this.setDetailsItemSchema(view.label_format, view.columns);

        return view;

      case 'form':

        const defaultRules = {
          submits: [],
          fields: [],
          records: [],
          emails: [],
        };

        view.rules = { ...defaultRules, ...view.rules };

        if (!view.groups) {
          view.groups = [];
        }

        // Ensure a default submit rule exists
        view = this.verifySubmitRules(view);

        view.groups = view.groups.map((group) => {
          group.columns = group.columns.map((column) => {
            if (!column) {
              return {
                inputs: [],
              };
            }

            column.inputs = column.inputs.map((input) =>

              // TODO: make this conditional on input type (only connections need filters)
              ({ ...SchemaUtils.inputDefaults(input.type), ...input }));

            return column;
          });

          return group;
        });

        return view;

      case 'list':

        if (!view.rules || !view.rules.fields) {
          view.rules = {
            fields: [],
          };
        }

        if (!view.label_format) {
          view.label_format = (view.columns[0] && view.columns[0].groups[0]) ? view.columns[0].groups[0].label_format : 'left';
        }

        if (!view.list_layout) {
          view.list_layout = 'full';
        }

        view.columns = this.setDetailsItemSchema(view.label_format, view.columns);

        return view;

      case 'map':

        this.setSchemaFilters(view.details);

        if (!view.details) {
          view.details = {
            columns: [],
          };
        }

        if (!view.details.label_format) {
          view.details.label_format = (view.details.columns[0] && view.details.columns[0].groups[0]) ? view.details.columns[0].groups[0].label_format : 'left';
        }

        view.details.columns = this.setDetailsItemSchema(view.details.label_format, view.details.columns);

        return view;

      case 'registration':

        if (!view.groups) {
          view.groups = [];
        }

        view.groups = view.groups.map((group) => {
          group.columns = group.columns.map((column) => {
            if (!column) {
              return {
                inputs: [],
              };
            }

            column.inputs = column.inputs.map((input) => ({ ...SchemaUtils.inputDefaults(), ...input }));

            return column;
          });

          return group;
        });

        return view;

      case 'report':

        // if first report has a source, then this is an old report where
        // the source needs to be migrated to the report level
        const report = hasIn(view, 'rows[0].reports[0]') ? view.rows[0].reports[0] : (hasIn(view, 'reports[0]') ? view.reports[0] : {});

        if (report.source && !view.source) {
          view.source = { ...this.defaultSource(), ...report.source };

          // TODO: do not delete since expected on server-side (consider forking)
          // delete report.source
        }

        if (report.source && !view.source.object) {
          view.source.object = report.source.object;

          // TODO: do not delete since expected on server-side (consider forking)
          // delete report.source
        }

        if (view.rows) {
          view.rows.forEach((row) => {
            row.reports.forEach((report) => {
              report.source = { ...this.defaultSource(), ...report.source }; // native

              // reports don't have source sorting
              delete report.source.sort;

              report.options = { ...this.defaultReportOptions(), ...report.options };

              report.settings = { ...this.defaultReportSettings(), ...report.settings };

              report.filters = { ...this.defaultReportFilters(), ...report.filters };

              report.layout = { ...this.defaultReportLayout(report), ...report.layout };

              // Kludge:: saw some reports where this is true but v3 doesn't care about this (it's set in v2 builder).
              // Hopefully we find the root cause of why those were set to true.
              report.preview = false;
            });
          });
        }

        if (view.reports && !view.rows) {
          view.rows = [];

          view.reports.forEach((report) => {
            view.rows.push({
              reports: [
                report,
              ],
            });
          });
        }

        // delete groups/reports for now
        delete view.groups;
        delete view.reports;

        // correct criteria = [] sources
        if (view.source) {
          view.source = { ...this.defaultSource(), ...view.source };

          if (Array.isArray(view.source.criteria) && view.source.criteria.length === 0) {
            view.source.criteria = this.defaultSource().criteria;
          }

          delete view.source.sort;
        }

        return view;

      case 'table':

        if (!view.columns) {
          view.columns = [];
        }

        view.columns = view.columns.map((col) => ({ ...SchemaUtils.columnDefaults(), ...col }));

        return view;

      case 'search':

        if (!view.groups) {
          view.groups = [];
        } else if (view.groups.initial) {
          view.groups = [
            {
              columns: [
                {
                  fields: [
                    view.groups.initial,
                  ],
                },
              ],
            },
          ];
        }

        view.groups = view.groups.map((group) => {
          group.columns = group.columns.map((column) => {
            column.fields = column.fields.map((input) => {
              input = { ...SchemaUtils.searchDefaults(), ...input };

              return input;
            });

            return column;
          });

          return group;
        });

        if (!view.label_format) {
          view.label_format = view.groups[0]?.label_format || 'left';
        }

        if (view.results_type === 'table') {
          view.results.columns = view.results.columns.map((column) => ({ ...SchemaUtils.columnDefaults(), ...column }));
        } else {
          if (!view.list_layout) {
            view.list_layout = 'full';
          }

          if (!view.results.label_format) {
            view.results.label_format = view.results?.columns[0]?.groups[0]?.label_format || 'left';
          }

          view.results.columns = this.setDetailsItemSchema(view.results.label_format, view.results.columns);
        }

        return view;

      default:

        return view;
    }
  }

  // Validate that components can work with this schema with 100% confidence
  sanitizeSchema() {
    if (this.type === 'search') {
      this.clearEmptyFieldSchemas();
      this.castFieldValueToArray();
    }

    if (this.type === 'table') {
      this.columns.forEach((col) => {
        const hasCharacterLimit = typeof col.character_limit !== 'undefined';

        // Use default character limit if invalid value is used
        if (hasCharacterLimit && !isCharacterLimitValid(col.character_limit)) {
          col.character_limit = defaultCharacterLimit;
        }
      });
    }
  }

  verifySubmitRules(view) {
    // Populate if rules are empty
    if (!view.rules) {
      view.rules = {
        submits: [
          getDefaultSubmitRuleSchema(view.type),
        ],
      };

      return view;
    }

    // Verify we have a default submit rule
    if (!view.rules.submits.some((rule) => rule.is_default)) {
      // Set a default submit rule
      const submitRule = getDefaultSubmitRuleSchema(view.type);

      // Set a starting rule key
      let uniqueKey = 1;

      // Ensure this key doesn't exist in this collection
      while (view.rules.submits.some((ruleToCheck) => ruleToCheck?.key === `submit_${uniqueKey}`)) {
        uniqueKey++;
      }

      submitRule.key = `submit_${uniqueKey}`;

      // Insert at the beginning. Corrupt schemas may have existing rules but no default
      view.rules.submits.splice(0, 0, submitRule);
    }

    return view;
  }

  // Ensure field schemas have no empty groups or columns
  clearEmptyFieldSchemas() {
    if (this.type === 'search') {
      // clear any empty groups and columns
      this.groups = this.groups.filter((group) => {
        group.columns = group.columns.filter((column) => !isEmpty(get(column, 'fields')));

        return group.columns.length;
      });
    }
  }

  /**
   * Make sure that fields values are arrays.
   */
  castFieldValueToArray() {
    this.groups?.forEach((group) => {
      group.columns?.forEach((column) => {
        column.fields?.forEach((fieldData) => {
          const field = store.getters.getField(fieldData.field);

          if (!field) {
            return;
          }

          const toArrayFieldTypes = [
            'connection',
          ];

          if (toArrayFieldTypes.includes(field.type) && !Array.isArray(fieldData.value)) {
            fieldData.value = (!isNil(fieldData.value)) ? [fieldData.value] : [];
          }
        });
      });
    });
  }

  defaultSource() {
    return {
      criteria: {
        match: 'all',
        rules: [],
        groups: [],
      },
      limit: '',
      sort: this.defaultSourceSort(),
    };
  }

  defaultSourceSort(sourceObject) {
    if (!sourceObject) {
      sourceObject = this.getSourceObject();
    }

    if (!sourceObject) {
      return [];
    }

    return [
      {
        field: sourceObject.sort.field,
        order: sourceObject.sort.order,
      },
    ];
  }

  setDefaults(view) {
    if (!view) {
      return;
    }

    const viewDefaults = {
      groups: [],
    };

    // Source Defaults
    const sourceViews = [
      'table',
      'list',
      'map',
      'calendar',
      'search',
    ];

    if (sourceViews.indexOf(view.type) > -1 && !view.source) {
      viewDefaults.source = this.defaultSource();
    }

    viewDefaults.design = {};

    switch (view.type) {
      case 'table':

        viewDefaults.no_data_text = 'No data';
        viewDefaults.totals = [];
        viewDefaults.preset_filters = [];
        viewDefaults.columns = [];
        viewDefaults.table_design = undefined;
        viewDefaults.table_design_active = false;
        viewDefaults.keyword_search_fields = 'view';

        break;

      case 'form':

        viewDefaults.submit_button_text = 'Submit';
        viewDefaults.rules = {
          submits: [],
          fields: [],
          records: [],
          emails: [],
        };

        break;

      case 'list':

        viewDefaults.hide_fields = false;
        viewDefaults.columns = [];
        viewDefaults.keyword_search_fields = 'view';

        break;

      case 'details':

        viewDefaults.hide_fields = false;
        viewDefaults.columns = [];

        break;

      case 'registration':

        viewDefaults.submit_button_text = 'Submit';

        break;

      case 'search':

        viewDefaults.submit_button_text = 'Submit';
        viewDefaults.totals = [];
        viewDefaults.hide_fields = false;
        viewDefaults.hide_empty = false;
        viewDefaults.results_type = 'table';
        viewDefaults.results = {
          columns: [],
        };
        viewDefaults.table_design = undefined;
        viewDefaults.table_design_active = false;
        viewDefaults.keyword_search_fields = 'view';

        break;

      case 'login':

        viewDefaults.submit_button_text = 'Login';
        viewDefaults.registration_forms = {};

        break;

      case 'menu':

        viewDefaults.format = 'none';
        viewDefaults.menu_links_design = undefined;
        viewDefaults.menu_links_design_active = false;

        break;

      case 'map':

        viewDefaults.units = 'miles';
        viewDefaults.pin_color_default = '#ea4336';
        viewDefaults.pin_colors = [];
        viewDefaults.details = {
          columns: [],
        };

        break;
    }

    return viewDefaults;
  }

  raw() {
    return this.attributes;
  }

  get(property) {
    // Using lodash get() so property can contain a nested path e.g. `details.columns`
    return get(this.attributes, property);
  }

  set(property, newVal) {
    // Using lodash set() so property can contain a nested path e.g. `details.columns`
    set(this.attributes, property, newVal);

    return newVal;
  }

  getDetailsLayout() {
    if (this.type === 'search' || this.type === 'list') {
      return this.get('list_layout') || 'full';
    }

    if (this.type === 'map' || this.type === 'calendar') {
      return this.get('details.layout') || 'full';
    }

    if (this.type === 'checkout') {
      return this.get('checkout_page.layout');
    }

    return this.get('layout') || 'full';
  }

  setDetailsLayout(newValue) {
    if (this.type === 'search' || this.type === 'list') {
      this.attributes.list_layout = newValue;
    }

    if (this.type === 'map' || this.type === 'calendar') {
      this.attributes.details.layout = newValue;
    }

    if (this.type === 'checkout') {
      this.attributes.checkout_page.layout = newValue;
    }

    this.attributes.layout = newValue;
  }

  getDetailsSchema() {
    if (this.type === 'search') {
      return this.get('results.columns') || [];
    }

    if (this.type === 'map' || this.type === 'calendar') {
      return this.get('details.columns') || [];
    }

    if (this.type === 'checkout') {
      return this.get('checkout_page.columns') || [];
    }

    return this.get('columns') || [];
  }

  setDetailsSchema(newValue) {
    if (this.type === 'search') {
      this.attributes.results.columns = newValue;
    }

    if (this.type === 'map' || this.type === 'calendar') {
      this.attributes.details.columns = newValue;
    }

    if (this.type === 'checkout') {
      this.attributes.checkout_page.columns = newValue;
    }

    this.attributes.columns = newValue;
  }

  // TODO: this is used when setting a view to active, but could probably be better named,
  // since this can be used when marking a view as being clean / fresh slate (not dirty)
  setToActive() {
    store.commit('setViewHasActiveUpdates', false);

    this.attributesRestore = cloneDeep(this.attributes);

    return this.attributesRestore;
  }

  cancelUpdates() {
    store.commit('setIsCancelling', true);
    store.commit('setViewHasActiveUpdates', false);

    // don't need to restore anything if this is a new view, it will be removed
    if (!this.isNew()) {
      // don't want to copy by reference here or changes to attributes will persist to attributesRestore
      this.attributes = cloneDeep(this.attributesRestore);

      // because report data changes with realtime previews, we also need to restore data when report updates are canceled
      if (this.type === 'report') {
        this.data = cloneDeep(this.dataRestore);
      }
    }

    return setTimeout(() => {
      store.commit('setIsCancelling', false);
    }, 100);
  }

  // API METHODS
  async create() {
    const viewRaw = cloneDeep(this.raw());

    // For views with `new` key populated, API will not set a new key if a key exists
    if (viewRaw.key && viewRaw.key === NEW_VIEW_KEY) {
      delete viewRaw.key;
    }

    /** @type {Page | RawPage} page */
    let page = store.getters.activePage;

    if (this.pageKey !== page.key) {
      page = store.getters.getPageByKey(this.pageKey);
    }

    viewRaw.pageGroups = page.groups;

    // Send API request to create the view on the server.
    // This will also update the page groups on the server (the server will replace the `new` with the view key).
    const response = await window.Knack.Api.createView(this.pageKey, viewRaw);

    // Make sure the view active updates flag is reset.
    store.commit('setViewHasActiveUpdates', false);

    return response;
  }

  async save() {
    const { commit, dispatch } = store;

    commit('setViewHasActiveUpdates', false);

    const saveAttributes = cloneDeep(this.raw());
    const { pageKey } = this;
    const viewKey = this.key;

    if (this.type === 'login') {
      // added these trying to get a login view to save without a 500. These are all extraneous properties.
      delete saveAttributes.child_views;
      delete saveAttributes.child_child_views;
      delete saveAttributes.child_scenes;
      delete saveAttributes.columns;
      delete saveAttributes.groups;
      delete saveAttributes.inputs;
      delete saveAttributes.links;
      delete saveAttributes.label;
      delete saveAttributes.design;
      delete saveAttributes.links;
    }

    const response = await window.Knack.Api.updateView(pageKey, viewKey, saveAttributes);

    // Update this view with anything returned from the server
    this.setView(response.view);

    // Also update the view locally because the handleChanges() flow will not do it in this case.
    await dispatch('page/view/updateViewLocally', {
      rawPageKey: pageKey,
      viewKey,
      viewUpdates: response.view,
    }, { root: true });

    // for login views we may need to reset page authentication properties based on login allowed_profiles
    if (this.type === 'login') {
      // Reprocessing the page will also sort the child pages.
      await dispatch('reprocessPage', { pageKey });
    } else {
      // Reset this pages child order since this view may have affected that
      await dispatch('sortChildPagesLocally', { pageKey });
    }

    // TODO: Update these active view flows to pull the data from the pageCache instead of copying it.
    this.setToActive();

    await this.loadData();

    return response;
  }

  // META METHODS

  isNew() {
    return this.key === NEW_VIEW_KEY;
  }

  hasSourceFilters() {
    if (!this.source || !this.source.criteria) {
      return false;
    }

    if (hasIn(this.source, 'criteria.rules') && this.source.criteria.rules.length) {
      return true;
    }

    if (this.source.criteria.groups && this.source.criteria.groups.length && this.source.criteria.groups[0].length) {
      return true;
    }

    return false;
  }

  isChildView() {
    return this.parent;
  }

  isCheckoutType() {
    const checkoutTypes = [
      'checkout',
      'charge',
      'customer',
    ];

    if (checkoutTypes.includes(this.type)) {
      return true;
    }

    return false;
  }

  isRecordDriven() {
    if (this.type === 'new') {
      return false;
    }

    if (this.isInsertForm()) {
      return false;
    }

    const nonRecords = [
      'login',
      'registration',
    ];

    if (nonRecords.indexOf(this.type) > -1) {
      return false;
    }

    if (this.isStatic()) {
      return false;
    }

    return true;
  }

  isStatic() {
    const statics = [
      'divider',
      'menu',
      'rich_text',
      'image',
    ];

    if (statics.indexOf(this.type) > -1) {
      return true;
    }

    return false;
  }

  isSingleRecordView() {
    if (this.type === 'details' || this.type === 'form') {
      return true;
    }

    if (this.isCheckoutType()) {
      return true;
    }

    return false;
  }

  isUserAuthenticated() {
    if (!this.source) {
      return false;
    }

    return this.source.authenticated_user === true;
  }

  searchViewHasKeyword() {
    if (this.type !== 'search') {
      return false;
    }

    return this.get('groups').find((group) => group.columns.find((column) => column.fields.find((input) => input.field === 'keyword_search')));
  }

  hasInlineEditingEnabled() {
    if (this.type === 'table') {
      return this.get('options.cell_editor');
    }

    if (this.type === 'search') {
      return this.get('cell_editor');
    }

    // No other view has inline editing options
    return false;
  }

  async loadIndividualReportData(rowIndex, itemIndex) {
    const report = this.get('rows')[rowIndex].reports[itemIndex];

    // KLUDGE:: eventually should remove reportKey as a requirement here
    const reportKey = 'preview';

    const reportDataResponse = await window.Knack.Api.getRecordsForReportPreview(this.pageKey, reportKey, {}, 1, 25, report);

    const reportData = { ...reportDataResponse.reports[0], key: reportKey };

    // const reportIndex = this.reports.findIndex(report => report.key === reportKey)

    // update

    const reportIndex = this.getReportIndex(rowIndex, itemIndex);

    this.data.reports.splice(reportIndex, 1, reportData);

    return reportDataResponse;
  }

  async getReportsData() {
    const reports = this.getReports();

    const reportDataResponses = await Promise.all(reports.map(async (report) => {
      const response = await window.Knack.Api.getRecordsForReportPreview(this.pageKey, this.key, report.filters, 1, 25, report);

      return response;
    }));

    const reportsData = reportDataResponses.reduce((memo, response) => {
      memo.push(...response.reports);
      return memo;
    }, []);

    return {
      reports: reportsData,
    };
  }

  getReports() {
    return this.attributes.rows.map((row) => row.reports.map((report) => report)).flat();
  }

  updateReportsSource(newSource) {
    this.attributes.rows = this.attributes.rows.map((row) => {
      row.reports = row.reports.map((report) => {
        report.source = {
          ...newSource,
          criteria: report.source.criteria,
        };
        return report;
      });

      return row;
    });
  }

  getReportIndex(rowIndex, itemIndex) {
    let reportIndex = itemIndex;

    for (let rCount = 0; rCount < rowIndex; rCount++) {
      reportIndex += this.rows[rCount].reports.length;
    }

    return reportIndex;
  }

  addChartToReport(reportType, source) {
    // if `source` is explicitly passed in (likely the scenario in which there are multiple possible sources)
    // then use that source; otherwise use instance source
    const chartSource = source || this.source;

    const newReport = defaultReport(reportType, chartSource);

    if (isEmpty(newReport.source.sort)) {
      newReport.source.sort = this.defaultSourceSort();
    }

    this.rows.push({
      reports: [
        newReport,
      ],
    });
  }

  setMeta(view) {
    // Properties for data source rules that all "many" record views share
    const sourceRules = {
      name: 'source',
      property: 'source.criteria',
      types: [
        'source',
      ],
    };

    // Properties for action rules that all detail and column based views share
    const actionRules = {
      name: 'action',
      types: [
        'record',
        'email',
        'submit',
        'action',
      ],
      property: 'action_rules',
    };

    const metaOptions = {
      calendar: {
        fields: {
          collections: [
            {
              name: 'details',
              property: 'details.columns',
              type: 'details',
            },
          ],
          aliases: {
            data: 'details',
          },
        },
        rules: {
          collections: [
            sourceRules,
            {
              ...actionRules,
              fields: 'details',
            },
          ],
        },
      },
      checkout: {
        fields: {
          collections: [
            {
              name: 'details',
              property: 'checkout_page.columns',
              type: 'details',
            },
          ],
          aliases: {
            data: 'details',
          },
        },
        rules: {
          collections: [
            {
              name: 'record',
              property: 'rules.records',
              types: [
                'record', // record | email | submit | actions
              ],
              canUseLoggedInUser: true,
            },
            {
              ...actionRules,
              fields: 'details',
            },
          ],
        },
      },
      details: {
        fields: {
          collections: [
            {
              name: 'details',
              property: 'columns',
              type: 'details',
            },
          ],
          aliases: {
            data: 'details',
          },
        },
        rules: {
          collections: [
            {
              name: 'actions',
              types: [
                'record',
                'email',
                'submit',
                'actions',
              ],
              property: 'action_rules',
              fields: 'details',
            },
          ],
        },
      },
      form: {
        fields: {
          collections: [
            {
              name: 'inputs',
              property: 'groups',
              type: 'inputs',
            },
          ],
          aliases: {
            data: 'inputs',
          },
        },
        rules: {
          collections: [
            {
              name: 'record',
              property: 'rules.records',
              types: [
                'record', // record | email | submit
              ],
              canUseLoggedInUser: true,
            },
          ],
        },
      },
      list: {
        fields: {
          collections: [
            {
              name: 'details',
              property: 'columns',
              type: 'details',
            },
          ],
          aliases: {
            data: 'details',
          },
        },
        rules: {
          collections: [
            sourceRules,
            {
              ...actionRules,
              fields: 'details',
            },
          ],
        },
      },
      map: {
        fields: {
          collections: [
            {
              name: 'details',
              property: 'details.columns',
              type: 'details',
            },
          ],
          aliases: {
            data: 'details',
          },
        },
        rules: {
          collections: [
            sourceRules,
            {
              ...actionRules,
              fields: 'details',
            },
          ],
        },
      },
      search: {
        fields: {
          collections: [
            {
              name: 'inputs',
              property: 'groups',
              type: 'search',
            },
            {
              name: 'columns',
              property: 'results.columns',
              type: 'columns',
            },
            {
              name: 'list',
              property: 'results.columns',
              type: 'details',
            },
          ],
          aliases: {
            data: 'columns',
            search: 'inputs',
            table: 'columns',
          },
        },
        rules: {
          collections: [
            sourceRules,
            {
              ...actionRules,
              // Action rules can live on either results type for Search views
              fields: (viewModel) => (viewModel.get('results_type') === 'list' ? 'details' : 'columns'),
            },
          ],
        },
      },
      table: {
        fields: {
          collections: [
            {
              name: 'columns',
              property: 'columns',
              type: 'columns',
            },
          ],
          aliases: {
            data: 'columns',
          },
        },
        rules: {
          collections: [
            sourceRules,
            {
              ...actionRules,
              fields: 'columns',
            },
          ],
        },
      },
      report: {
        fields: {
          collections: [
            {
              name: 'reports',
              property: 'rows',
              type: 'reports',
            },
          ],
          aliases: {
            data: 'reports',
          },
        },
        rules: {
          collections: [
            sourceRules,
            {
              ...sourceRules,
              name: 'reportSources',
              fields: 'reports',
            },
          ],
        },

      },
    };

    const metaDefault = {
      fields: {
        collections: [],
        aliases: {},
      },
      rules: {
        collections: [],
        aliases: {},
      },
    };

    // Ensure we're covering any checkout variations
    metaOptions.charge = metaOptions.customer = metaOptions.checkout;

    this.meta = metaOptions[view.type] || metaDefault;
  }

  /**
   * Get a rules collection stored in this schema. Rules can live on view fields, view source, or direct property.
   * @param {String} whichCollection an alias or name of the collection of rules to get
   * @returns {Array} a collection of rules
  */
  getRulesCollection(whichCollection) {
    const rulesMeta = this.getMetaRulesCollection(whichCollection);

    if (!rulesMeta) {
      return [];
    }

    const { property } = rulesMeta;

    // Check if the rules live on a fields collection
    if (rulesMeta.fields) {
      const whichFields = isFunction(rulesMeta.fields) ? rulesMeta.fields(this) : rulesMeta.fields;

      const fieldMetaCollection = this.getMetaFieldCollection(whichFields);

      let rules = [];

      this.getItemsFromCollection(fieldMetaCollection).forEach((field) => {
        let itemRule = get(field, property);

        // Sources have some complex rules to deal with
        if (whichCollection === 'reportSources') {
          itemRule = this.getRulesCollectionForSource(itemRule);
        } else {
          // Standardize rules as stored in an arry (e.g. Action Rules stores multiple actions)
          if (!Array.isArray(itemRule)) {
            itemRule = Array(itemRule);
          }
        }

        rules = [
          ...rules,
          ...itemRule,
        ];
      });

      return rules.filter(Boolean);
    }

    // Check if this is source, which has some complexity with rules
    if (whichCollection === 'source') {
      const source = this.get(property);

      return this.getRulesCollectionForSource(source);
    }

    return this.get(property);
  }

  /**
   * Get any rules stored in a source, either directly added or in groups
   * @param {Object} a source schema
   * @returns {Array} a collection of rules
  */
  getRulesCollectionForSource(source) {
    let rules = [];

    if (Array.isArray(source.rules)) {
      rules = [
        ...source.rules,
      ];
    }

    source.groups.forEach((group) => {
      rules = [
        ...rules,
        ...group,
      ];
    });

    return rules;
  }

  /**
   * Get the meta description rules collection
   * @param {String} whichCollection An alias or name of the meta information to get
   * @returns {Object} of metaRulesCollection
  */
  getMetaRulesCollection(whichCollection) {
    // find the meta collection and return
    return this.getMetaCollection('rules', whichCollection);
  }

  /**
   * Checks if a rule references a user role (profile key) from the provided array
   * @param {Object} rule - A rule from the schema
   * @param {Array} userRoles - An array of user profile keys that the rules must reference
   * @returns {Boolean} true if the rule does reference one of the provided user roles
  */
  checkIfRuleReferencesUserRoles(rule, userRoles = []) {
    if (rule?.operator === 'user') {
      // Get the value field
      const field = store.getters.getField(rule.field);

      // Get the object this connection references
      const object = store.getters.getObject(field.relationship?.object);

      return userRoles.includes(object.profile_key);
    }

    return false;
  }

  /**
   * Checks if a rule references a user role (profile key) not in the provided array
   * @param {Object} rule - A rule from the schema
   * @param {Array} userRoles - An array of user profile keys that the rules must reference
   * @returns {Boolean} true if the rule references a user role not in the provided array
  */
  checkIfRuleValueReferencesUserRoles(value, userRoles = []) {
    if (value?.type === 'user') {
      // Get the value field
      const field = store.getters.getField(value.field);

      // Get the object this connection references
      const object = store.getters.getObject(field.relationship?.object);

      return userRoles.includes(object.profile_key);
    }

    return false;
  }

  /**
   * Get which types of rules from this view reference the logged-in user.
   * @param {Array} userRoles An array of user profile keys that the rules must reference
   * @returns {{source: Boolean, record: Boolean, action: Boolean}} an object indexed by the types of rules that can contain user references
  */
  getWhichRuleTypesReferenceTheLoggedInUser(userRoles = []) {
    const sourceRules = this.getRulesCollection('source').some((rule) => this.checkIfRuleReferencesUserRoles(rule, userRoles));

    // Reports can have sources on the report level
    const fieldSourceRules = this.getRulesCollection('reportSources').some((rule) => this.checkIfRuleReferencesUserRoles(rule, userRoles));

    const recordRules = this.getRulesCollection('record').some((rule) => rule.values.some((value) => this.checkIfRuleValueReferencesUserRoles(value, userRoles)));

    const actionRules = this.getRulesCollection('action').some((rule) => rule?.record_rules?.some((recordRule) => recordRule?.values?.some((value) => this.checkIfRuleValueReferencesUserRoles(value, userRoles))));

    return {
      source: sourceRules || fieldSourceRules,
      record: recordRules,
      action: actionRules,
    };
  }

  /**
   * Remove any rules that reference the logged in user from every rules collection
  */
  reduceRulesThatReferenceLoggedInUser(userRoles = []) {
    // Remove from source rules
    this.reduceRulesCollection('source', (collection, rule) => {
      if (!this.checkIfRuleReferencesUserRoles(rule, userRoles)) {
        collection.push(rule);
      }

      return collection;
    });

    // Remove from report rules
    this.reduceRulesCollection('reportSources', (collection, rule) => {
      if (!this.checkIfRuleReferencesUserRoles(rule, userRoles)) {
        collection.push(rule);
      }

      return collection;
    });

    // Remove from record rules
    this.reduceRulesCollection('record', (collection, rule) => {
      rule.values = rule.values.filter((value) => !this.checkIfRuleValueReferencesUserRoles(value, userRoles));

      if (rule.values.length) {
        collection.push(rule);
      }

      return collection;
    });

    // Remove from action rules
    this.reduceRulesCollection('action', (collection, rule) => {
      rule.record_rules = rule.record_rules.map((recordRule) => {
        if (!isEmpty(recordRule.values)) {
          recordRule.values = recordRule.values.filter((value) => !this.checkIfRuleValueReferencesUserRoles(value, userRoles));
        }

        return recordRule;
      });

      collection.push(rule);

      return collection;
    });
  }

  /**
   * Remove any rules from a given collection according to a reduce function
   * @param {String} whichCollection An alias or name of the meta information to get
   * @param {Function} reduceFunction A function to pass to reduce
  */
  reduceRulesCollection(whichCollection, reduceFunction) {
    const rulesMeta = this.getMetaRulesCollection(whichCollection);

    // Return early if this view doesn't have this collection
    if (!rulesMeta) {
      return;
    }

    const { property } = rulesMeta;

    // Check if the rules live on a fields collection
    if (rulesMeta.fields) {
      const whichFields = isFunction(rulesMeta.fields) ? rulesMeta.fields(this) : rulesMeta.fields;

      // Get the meta about the view items that may contain these rules
      const itemMetaCollection = this.getMetaFieldCollection(whichFields);

      const { type: itemsType, property: itemsProperty } = itemMetaCollection;

      // Get the actual items from this view
      const itemsCollection = this.get(itemsProperty);

      this.iterateItemsFromCollection(itemsCollection, itemsType, (item) => {
        let itemRules = get(item, property);

        if (itemRules) {
          // Source has weird complexity
          if (whichCollection === 'reportSources') {
            itemRules = this.reduceRulesCollectionForSource(itemRules, reduceFunction);
          } else {
            itemRules = itemRules.reduce(reduceFunction, []);
          }

          set(item, property, itemRules);
        }
      });

      return;
    }

    // Check if this is source, which has some complexity with rules
    if (whichCollection === 'source') {
      const source = this.reduceRulesCollectionForSource(this.get(property), reduceFunction);

      this.set(property, source);

      return;
    }

    // Otherwise reduce as a normal property
    this.set(property, this.get(property).reduce(reduceFunction, []));
  }

  /**
   * Remove any rules from a given collection according to a reduce function
   * @param {Object} a source object from the view schema
   * @param {Function} reduceFunction A function to pass to reduce
  */
  reduceRulesCollectionForSource(source, reduceFunction) {
    source.rules = source.rules.reduce(reduceFunction, []);

    source.groups = source.groups.reduce((collection, group) => {
      group = group.reduce(reduceFunction, []);

      if (group.length) {
        collection.push(group);
      }

      return collection;
    }, []);

    return source;
  }

  addAllFields(whichFieldCollections) {
    // set active object
    const object = this.getSourceObject();

    // fieldCollection maps to which collection to get fields from. E.g. search can be inputs, list, or table
    const existingFields = this.getFields(whichFieldCollections);

    for (const field of object.fields) {
      // Ensure the active view doesn't already have this field
      if (existingFields.find((existingField) => existingField.key === field.key)) {
        continue;
      }

      // add Item
      this.addFieldToFieldCollection(field, whichFieldCollections);
    }
  }

  addFieldToFieldCollection(field, whichFieldCollections) {
    const addFieldFunctions = {
      columns: 'addItemToColumnsCollection',
      inputs: 'addItemToInputsCollection',
      search: 'addItemToSearchCollection',
      details: 'addItemToDetailsCollection',
    };

    const createItemFunctions = {
      columns: 'createColumnItemFromField',
      inputs: 'createInputItemFromField',
      search: 'createSearchItemFromField',
      details: 'createDetailsItemFromField',
    };

    const fieldMetaCollections = this.getMetaFieldCollections(whichFieldCollections);

    // Iterate through all the collections we want to add the field to
    for (const fieldMetaCollection of fieldMetaCollections) {
      const { property, type } = fieldMetaCollection;

      // Convert the field to an item to add
      const createItemFunction = createItemFunctions[type];
      const item = this[createItemFunction](field);

      // Add the item to the collection
      const addFieldFunction = addFieldFunctions[type];
      const itemSchema = this.get(property);

      this[addFieldFunction](itemSchema, item);
    }
  }

  createSearchItemFromField(field) {
    const item = {
      field: field.key,
      name: field.name,
    };

    return { ...SchemaUtils.searchDefaults(), ...item };
  }

  addItemToSearchCollection(itemSchema, item) {
    // Ensure the schema is correct
    this.buildDefaultSearchSchema(itemSchema);

    // add to last group
    const lastGroupIndex = itemSchema.length - 1;

    const lastColumnIndex = itemSchema[lastGroupIndex].columns.length - 1;

    itemSchema[lastGroupIndex].columns[lastColumnIndex].fields.push(item);
  }

  // TODO:: likely a better way to do this
  buildDefaultSearchSchema(itemSchema, item) {
    if (!itemSchema.length) {
      itemSchema.push({
        columns: [
          {
            fields: [],
          },
        ],
      });
    }
  }

  createInputItemFromField(field) {
    const item = {
      field: {
        key: field.key,
      },
      id: field.key,
      label: field.name,
      type: field.type,
    };

    if (field.type === 'connection') {
      item.source = {
        filters: [],
      };
    }

    return { ...SchemaUtils.inputDefaults(), ...item };
  }

  addItemToInputsCollection(itemSchema, item) {
    // Ensure the schema is correct
    this.buildDefaultInputsSchema(itemSchema);

    // add to last group
    const lastGroupIndex = itemSchema.length - 1;

    const lastColumnIndex = itemSchema[lastGroupIndex].columns.length - 1;

    itemSchema[lastGroupIndex].columns[lastColumnIndex].inputs.push(item);
  }

  // TODO:: likely a better way to do this
  buildDefaultInputsSchema(itemSchema) {
    if (!itemSchema.length) {
      itemSchema.push({
        columns: [
          {
            inputs: [],
          },
        ],
      });
    }
  }

  createColumnItemFromField(field) {
    const item = {
      field: {
        key: field.key,
      },
      id: field.key,
      header: field.name,
      type: 'field',
    };

    return { ...SchemaUtils.columnDefaults(), ...item };
  }

  addItemToColumnsCollection(itemSchema, item) {
    itemSchema.push(item);
  }

  addItemToDetailsCollection(itemSchema, item) {
    const lastLayoutIndex = (itemSchema) ? itemSchema.length - 1 : 0;

    // Ensure the schema is correct
    this.buildDefaultDetailsSchema(itemSchema, lastLayoutIndex);

    const lastGroupIndex = itemSchema[lastLayoutIndex].groups.length - 1;
    const lastColumnIndex = itemSchema[lastLayoutIndex].groups[lastGroupIndex].columns.length - 1;

    itemSchema[lastLayoutIndex].groups[lastGroupIndex].columns[lastColumnIndex].push(item);
  }

  buildDefaultDetailsSchema(detailsSchema, layoutIndex = 0) {
    const defaultSchema = {
      groups: [{
        columns: [[]],
      }],
    };

    if (!detailsSchema[layoutIndex]) {
      detailsSchema.splice(layoutIndex, 0, defaultSchema);
      return;
    }

    if (!detailsSchema[layoutIndex].groups) {
      detailsSchema[layoutIndex].groups = defaultSchema.groups;
      return;
    }

    if (!detailsSchema[layoutIndex].groups[0]) {
      detailsSchema[layoutIndex].groups.splice(0, 0, defaultSchema.groups[0]);
      return;
    }

    if (!detailsSchema[layoutIndex].groups[0].columns) {
      detailsSchema[layoutIndex].groups[0].columns = defaultSchema.groups[0].columns;
      return;
    }

    if (!detailsSchema[layoutIndex].groups[0].columns[0]) {
      detailsSchema[layoutIndex].groups[0].columns.splice(0, 0, defaultSchema.groups[0].columns[0]);
    }
  }

  createDetailsItemFromField(field) {
    const item = {
      key: field.key,
      name: field.name,
      label: field.name,
    };

    return { ...SchemaUtils.detailDefaults(), ...item };
  }

  setFeldItemToAdd(field) {
    if (this.view.type === 'search' && this.viewType === 'form') {
      return {
        field: field.key,
        name: field.name,
        operator: 'is any',
      };
    }

    if (this.isDetails) {
      return {
        key: field.key,
        name: field.name,
        label: field.name,
      };
    }

    const item = {
      field: {
        key: field.key,
      },
      id: field.key,
      label: field.name,
      type: 'field',
    };

    // non-form items
    if (this.localViewType !== 'form') {
      return item;
    }

    // forms have types = field type
    item.type = field.type;

    // form items
    if (field.type === 'connection') {
      item.source = {
        filters: [],
      };
    }

    return item;
  }

  removeAllFields(whichFields) {
    const fieldMetaCollections = this.getMetaFieldCollections(whichFields);

    for (const fieldMetaCollection of fieldMetaCollections) {
      const { type, property } = fieldMetaCollection;

      // TODO: Might be worth making these individual collection functions like the others
      switch (type) {
        case 'details':

          this.set(property, [
            {
              groups: [
                {
                  columns: [
                    [],
                  ],
                },
              ],
            },
          ]);

          break;

        case 'columns':

          this.set(property, []);

          break;

        case 'inputs':

          this.set(property, [
            {
              columns: [
                {
                  inputs: [],
                },
              ],
            },
          ]);

          break;

        case 'search':

          this.set(property, [
            {
              columns: [
                {
                  fields: [],
                },
              ],
            },
          ]);

          break;
      }
    }
  }

  /**
   * Get fields that this view uses
   * @param {String} whichFields an alias or name of the collection of fields to get
   * @param {Array} whichFields an array of collection strings to get
   * @param {Object} options
   * @returns {Array} at minimum a field of keys with additional optional properties
  */
  getFields(whichFields = 'all', options = {}) {
    const fieldMetaCollections = this.getMetaFieldCollections(whichFields);
    let fields = [];

    // iterate through the field collections to aggregate fields
    for (const fieldMetaCollection of fieldMetaCollections) {
      fields = [
        ...fields,
        ...this.getFieldsFromCollection(fieldMetaCollection, options),
      ];
    }

    return fields;
  }

  /**
   * Determines which collections to return meta information about
   * @param {String} whichFields an alias or name of the collection of fields to get
   * @param {Array} whichFields an array of collection strings to get
   * @returns {Array} of metaFieldCollections
  */
  getMetaFieldCollections(whichFields = 'all') {
    if (!get(this, 'meta.fields.collections')) {
      return [];
    }

    if (whichFields === 'all') {
      return (this.meta.fields.collections || []).filter(Boolean);
    }

    if (Array.isArray(whichFields)) {
      let fieldCollections = [];

      for (const whichCollection of whichFields) {
        fieldCollections = [
          ...fieldCollections,
          this.getMetaFieldCollection(whichCollection),
        ];
      }

      return fieldCollections.filter(Boolean);
    }

    if (typeof whichFields === 'string') {
      return ([
        this.getMetaFieldCollection(whichFields),
      ]).filter(Boolean);
    }

    return [];
  }

  /**
   * Get a meta collection either by alias or directly by name
   * @param {String} the type of collection to get, like rules or fields
   * @param {String} whichCollection an alias or name of the meta information to get
   * @returns {Object} of metaFieldCollection
  */
  getMetaCollection(collectionType, whichCollection) {
    if (!this.meta[collectionType]) {
      return null;
    }

    // find the meta collection and return
    return this.meta[collectionType]?.collections.find((collection) => collection.name === whichCollection);
  }

  /**
   * Get a meta collection either by alias or directly by name
   * @param {String} whichCollection an alias or name of the meta information to get
   * @returns {Object} of metaFieldCollection
  */
  getMetaFieldCollection(whichCollection) {
    // Some views can display data in multiple types, like search with table/list
    if (whichCollection === 'data') {
      whichCollection = this.getMetaFieldCollectionForData();
    }

    // first check if it's an alias
    if (this.meta.fields.aliases[whichCollection]) {
      whichCollection = this.meta.fields.aliases[whichCollection];
    }

    // find the meta collection and return
    return this.getMetaCollection('fields', whichCollection);
  }

  /**
   * Some views can switch between different view types for their data, like Search.
  */
  getMetaFieldCollectionForData() {
    if (this.type === 'search') {
      // search can display results as a `table` or `list`
      return this.get('results_type');
    }

    return 'data';
  }

  /**
   * Get fields from a specific collection on the view
   * @param {Object} fieldMetaCollection the meta information describing the collection of fields to get
   * @param {Object} options options to control what's returned about fields along with field keys
   * @returns {Array} at minimum a field of keys with additional optional properties
  */
  getFieldsFromCollection(fieldMetaCollection, options) {
    const defaults = {
      includeLabels: false,
      includeRules: false,
      includeModel: false, // attach the entire field model
    };

    options = { ...defaults, ...options };

    const { type, property } = fieldMetaCollection;

    // Get the actual fields from this view
    const fieldsCollection = this.get(property);

    // Figure out which function to call based on the type of fields this collection stores
    // TODO: these could be asbtracted out and just use the iterators if there was a smidge more uniformity in collections
    const getFieldsFromCollectionFunction = {
      columns: 'getFieldsFromColumnsCollection',
      inputs: 'getFieldsFromInputsCollection',
      search: 'getFieldsFromSearchCollection',
      details: 'getFieldsFromDetailsCollection',
      reports: 'getFieldsFromReportsCollection',
    }[type];

    // fields will include key/label
    const fields = this[getFieldsFromCollectionFunction](fieldsCollection, options);

    return fields.map((field) =>

      // TODO:: structure this field based on provided options
      field);
  }

  /**
   * Get items from a specific collection on the view
   * @param {Object} fieldMetaCollection the meta information describing the collection of fields to get
   * @param {Object} options options to control what's returned about fields along with field keys
   * @returns {Array} at minimum a field of keys with additional optional properties
  */
  getItemsFromCollection(fieldMetaCollection) {
    const { type, property } = fieldMetaCollection;

    // Get the actual fields from this view
    const itemsCollection = this.get(property);

    const items = [];

    this.iterateItemsFromCollection(itemsCollection, type, (item) => items.push(item));

    return items;
  }

  iterateItemsFromCollection(itemsCollection, type, iteratorFunction) {
    // Figure out which function to call based on the type of fields this collection stores
    // TODO: these could be asbtracted out and just use the iterators if there was a smidge more uniformity in collections
    const iterateCollectionFunction = {
      columns: 'iterateColumnsCollection',
      inputs: 'iterateInputsCollection',
      search: 'iterateSearchCollection',
      details: 'iterateDetailsCollection',
      reports: 'iterateReportsCollection',
    }[type];

    this[iterateCollectionFunction](itemsCollection, iteratorFunction);
  }

  getFieldsFromColumnsCollection(columns, options) {
    const { includeLabels } = options;
    const fields = [];

    this.iterateColumnsCollection(columns, (item) => {
      // link columns (view details, edit, delete) do not have a field attribute
      if (!hasIn(item, 'field') || isEmpty(item.field.key)) {
        return;
      }

      const field = {
        key: item.field.key,
      };

      if (includeLabels) {
        field.label = item.header;
      }

      fields.push(field);
    });

    return fields;
  }

  iterateColumnsCollection(columns, iteratorFunction) {
    columns.forEach((item) => iteratorFunction(item));
  }

  getFieldsFromReportsCollection(rows, options) {
    const { includeLabels } = options;
    const fields = [];

    this.iterateReportsCollection(rows, (item) => {
      if (item.groups?.length === 0) {
        return;
      }

      const field = {
        key: item.groups[0].field,
      };

      if (includeLabels) {
        field.label = item.groups[0].label;
      }

      fields.push(field);
    });

    return fields;
  }

  iterateReportsCollection(rows, iteratorFunction) {
    rows.forEach((row) => row?.reports.forEach((report) => iteratorFunction(report)));
  }

  getFieldsFromDetailsCollection(columns, options) {
    const { includeLabels } = options;
    const fields = [];

    this.iterateDetailsCollection(columns, (item) => {
      if (!item.key) {
        return;
      }

      const field = {
        key: item.key,
      };

      if (includeLabels) {
        field.label = item.label;
      }

      fields.push(field);
    });

    return fields;
  }

  iterateDetailsCollection(columns, iteratorFunction) {
    columns.forEach((column) => {
      column.groups.forEach((group) => {
        group.columns.forEach((column) => {
          column.forEach((item) => iteratorFunction(item));
        });
      });
    });
  }

  getFieldsFromInputsCollection(groups, options) {
    const {
      includeLabels,
      ignoreTypes = [],
    } = options;

    const fields = [];

    this.iterateInputsCollection(groups, (item) => {
      if (!item.field) {
        return;
      }

      if (ignoreTypes.includes(item.type)) {
        return;
      }

      const field = {
        key: item.field.key || item.field,
      };

      if (includeLabels) {
        field.label = item.label;
      }

      fields.push(field);
    });

    return fields;
  }

  iterateInputsCollection(groups, iteratorFunction) {
    groups.forEach((group) => {
      group.columns.forEach((column) => {
        column.inputs.forEach((item) => iteratorFunction(item));
      });
    });
  }

  getFieldsFromSearchCollection(groups, options) {
    const { includeLabels } = options;
    const fields = [];

    this.iterateSearchCollection(groups, (item) => {
      if (!item.field) {
        return;
      }

      const field = {
        key: item.field.key || item.field,
      };

      if (includeLabels) {
        field.label = item.label;
      }

      fields.push(field);
    });

    return fields;
  }

  iterateSearchCollection(groups, iteratorFunction) {
    groups.forEach((group) => {
      group.columns.forEach((column) => {
        column.fields.forEach((item) => iteratorFunction(item));
      });
    });
  }

  // Get all the fields this view uses, along with the labels
  getDataFieldsWithLabels() {
    const whichFields = 'data';
    const options = {
      includeLabels: true,
    };

    return this.getFields(whichFields, options).map((field) => {
      const matchedField = store.getters.getField(field.key);

      // Populate any blank labels with the field name
      if (matchedField && !field.label) {
        field.label = matchedField.name;
      }

      return field;
    });
  }

  getDetailsLabelFormat() {
    const detailViews = [
      'calendar',
      'map',
    ];

    if (detailViews.includes(this.type)) {
      return this.get('details').label_format;
    }

    if (this.type === 'search') {
      return this.get('results').label_format;
    }

    return this.get('label_format');
  }

  async loadData() {
    // TODO use options to filter records?
    if (!this.isRecordDriven()) {
      return;
    }

    let response;

    try {
      response = await this.getData();
    } catch (getDataError) {
      // For now, ignore any errors when trying to get data.
      return;
    }

    this.onGetDataSuccess(response);
  }

  getReportSampleData() {
    const reports = this.getReports();

    const reportDataResponses = reports.map((report) => {
      report = cloneDeep(report);

      const reportFieldKey = report.groups[0].field;
      const object = store.getters.getObject(report.source.object);
      const field = object.getField(reportFieldKey);

      report.filter_groups = [];
      report.filters = [];

      const reportOutput = SampleHelper.generateSampleDataForReport({
        report,
      }, field);

      return reportOutput[0];
    });

    return {
      reports: reportDataResponses,
    };
  }

  getSampleData(queryVars) {
    if (this.type === 'report') {
      return this.getReportSampleData();
    }

    const object = this.getSourceObject();

    let fields = object.fields.map((field) => {
      const { key, format, type } = field.attributes;

      return {
        [key]: {
          fieldType: type, fieldFormat: format,
        },
      };
    });

    const state = {
      autoIncrement: 1,
    };

    const sampleOptions = {};

    if (this.type === 'calendar') {
      const now = moment();

      // We want the dates to return as momentJS objects so we don't have to parse them later.
      sampleOptions.formatDateAsMoment = true;

      // We need sample data for calendars to exist within the current month.
      // Without this, the dates could be at any time and would not show up in the preview.
      sampleOptions.dateOptions = {
        year: now.year(),
        month: now.month(),
      };

      if (this.attributes.events?.view === 'agendaDay') {
        // If the calendar only shows a single day, generate all the data for today.
        sampleOptions.dateOptions.day = now.date();
      }
    }

    fields = Object.assign({}, ...fields);
    const fieldKeys = Object.keys(fields);

    const records = [];

    if (this.isSingleRecordView()) {
      const record = SampleHelper.getSampleRecord(fields, fieldKeys, state, sampleOptions);

      records.push(record);
    } else {
      for (let recordIndex = 0; recordIndex < queryVars.recordsPerPage; recordIndex++) {
        const record = SampleHelper.getSampleRecord(fields, fieldKeys, state, sampleOptions);

        records.push(record);
      }
    }

    return {
      records,
      total_pages: 2,
      total_records: records.length,
      current_page: 1,
    };
  }

  async getData(queryVars = {}) {
    // defaults for query vars
    const defaults = {
      page: 1,
      recordsPerPage: this.get('rows_per_page') || 25,
    };

    queryVars = { ...defaults, ...queryVars };

    // sample data
    if (store.getters.pagePreviewType !== 'live') {
      return this.getSampleData(queryVars);
    }

    // single records
    if (this.isSingleRecordView()) {
      const recordId = store.getters.pagePreviewId;

      if (isNil(recordId)) {
        // Instead of hitting the server with an undefined record id, just return no records.
        return Promise.resolve({
          total_pages: 0,
          current_page: 1,
          total_records: 0,
          records: [],
        });
      }

      return this.getLiveRecord(recordId);
    }

    switch (this.type) {
      case 'report':

        return this.getReportsData();

      case 'calendar':

        const events = this.get('events');

        const rangeTypes = {
          agendaDay: 'day',
          agendaWeek: 'week',
        };

        const range = rangeTypes[events.view] || events.view;

        queryVars.filters = {
          match: 'and',
          rules: [
            {
              field: events.event_field.key,
              operator: 'is after',
              value: moment().startOf(range).toISOString(),
            },
            {
              field: events.event_field.key,
              operator: 'is before',
              value: moment().endOf(range).toISOString(),
            },
          ],
        };

        break;
      case 'map':

        if (this.get('starting_point') === 'address') {
          queryVars.filters = [
            {
              field: this.get('address_field').key,
              operator: 'near',
              value: this.get('starting_address'),
              range: this.get('default_range'),
              units: this.get('units') || 'miles',
            },
          ];
        }

        break;
    }

    return this.getLiveRecords(queryVars);
  }

  getLiveRecord(recordId) {
    if (this.isNew()) {
      return window.Knack.Api.getRecord(this.source.object, recordId);
    }

    return window.Knack.Api.getRecordForView(this.pageKey, this.key, recordId);
  }

  getLiveRecords(queryVars = {}) {
    if (this.isNew()) {
      if (!this.source.object) {
        throw new Error('Cannot get data for new View if no source object is set.');
      }

      return window.Knack.Api.getRecords(this.source.object);
    }

    const pageRecordIds = [];

    // sample data
    if (store.getters.pagePreviewType === 'live') {
      pageRecordIds.push({
        key: `${store.getters.activePage.slug}_id`,
        value: store.getters.pagePreviewId,
      });
    }

    return window.Knack.Api.getRecordsForView(this.pageKey, this.key, queryVars.filters, queryVars.page, queryVars.recordsPerPage, null, null, pageRecordIds);
  }

  onGetDataSuccess(response, onSuccess) {
    this.setData(response);

    // pass through any additional onSuccess methods
    if (onSuccess) {
      onSuccess(response);
    }
  }

  setData(data) {
    // menus and other static views don't store data
    if (this.isStatic()) {
      return;
    }

    // views with single records
    if (this.isSingleRecordView() && !get(data, 'records')) {
      data = {
        records: [
          data,
        ],
        total_records: 1,
        total_pages: 1,
      };
    }

    // did records happen?
    // TODO: This doesn't work with calendars, which needs proper `_raw` values for dates
    if (![
      'calendar',
      'report',
    ].includes(this.type) && data.records && data.records.length === 0) {
      const record = {};

      // generate a sample record
      this.getDataFieldsWithLabels().forEach((field) => {
        record[field.key] = `<em>${field.label} sample</em>`;
      });

      data.records.push(record);
      data.total_records = 1;
      data.total_pages = 1;
    }

    this.data = {
      ...data,
      isLoaded: true,
    };

    // because report data changes with realtime previews, we also need to restore data when report updates are canceled
    if (this.type === 'report') {
      this.dataRestore = cloneDeep(this.data);
    }

    return this.data;
  }

  dataIsLoaded() {
    if (!this.isRecordDriven()) {
      return true;
    }

    return this.data.isLoaded;
  }

  getDataRecord(recordId) {
    return this.data.records.find((record) => record.id === recordId);
  }

  // for form views, to parse out columns/rows
  // TODO:: flatten/normalize this heirarchy
  getInputs() {
    const inputViews = [
      'form',
      'registration',
      'calendar',
      'checkout',
    ];

    if (!inputViews.includes(this.type)) {
      return [];
    }

    let inputs = [];

    let inputGroups = this.get('groups');

    // calendar has an add event form
    if (this.type === 'calendar') {
      inputGroups = this.get('form').groups;
    }

    inputGroups.forEach((group) => {
      group.columns.forEach((column) => {
        inputs = inputs.concat(column.inputs);
      });
    });

    return inputs;
  }

  getSourceObject() {
    const sourceObject = get(this.source, 'object');

    if (!sourceObject) {
      return null;
    }

    return store.getters.getObject(sourceObject);
  }

  // use the fields from the flattened inputs to return options used for <select>s
  getInputFieldOptions() {
    const options = [];
    const object = this.getSourceObject();

    this.getInputs().forEach((input) => {
      if (input && input.field) {
        const field = object.getField(input.field.key);

        options.push({
          value: input.field.key,
          label: field.name,
        });
      }
    });

    if (!options.length) {
      options.push({
        value: '',
        label: 'No eligible fields exist',
      });
    }

    return options;
  }
}

export default View;
