<template>
  <Modal
    id="connection-add-modal"
    title="Add Connection Field"
    :back="backLink"
  >
    <div v-if="wizardStep">
      <WizardStep
        v-model:wizard-step="wizardStep"
        class="first"
        :step="1"
      >
        <template #edit>
          <div class="mb-6">
            <div>
              <label>
                Connected to
              </label>
              <div class="flex items-center max-w-fit rounded-lg px-2 py-1 bg-subtle text-emphasis mt-2">
                <Icon
                  class="w-4 h-4 mr-1 text-subtle"
                  type="table-cells"
                />
                <span>
                  {{ connectedObject.inflections.plural }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #intro>
          <div>
            <label>
              Choose Table to connect to
            </label>
          </div>
        </template>
        <form class="margin-bottom-double mt-2 mb-6">
          <div>
            <select
              v-model="connectedObjectKey"
              data-cy="connection-object-select"
            >
              <option
                v-for="obj in objectsByMenuOrder"
                :key="obj.key"
                :value="obj.key"
              >
                {{ obj.name }}
              </option>
            </select>
          </div>
        </form>

        <div class="flex justify-end">
          <a
            class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base leading-4 font-medium m-0"
            data-cy="next"
            @click="wizardStep++"
          >
            Next
          </a>
        </div>
      </WizardStep>

      <WizardStep
        v-model:wizard-step="wizardStep"
        :step="2"
      >
        <template #edit>
          <div class="mb-6">
            Connecting to {{ fromName }}
          </div>
        </template>
        <template #intro>
          <div>
            <label>
              Relationship
            </label>
          </div>
        </template>
        <form class="-bottom-double">
          <div class="mb-2">
            <label>
              <div>
                Each <b>{{ object.inflections.singular }}</b> connects with<select
                  v-model="connectedHas"
                  data-cy="connection-from-type"
                  class="inline"
                  style="display: inline; width: 80px;"
                >
                  <option value="one">
                    one
                  </option>
                  <option value="many">
                    many
                  </option>
                </select><b>{{ fromName }}</b>
              </div>
            </label>
          </div>
          <div>
            <label>
              <div>
                Each <b>{{ connectedObject.inflections.singular }}</b> connects with<select
                  v-model="connectedBelongsTo"
                  data-cy="connection-to-type"
                  class="inline"
                  style="display: inline; width: 80px;"
                >
                  <option value="one">
                    one
                  </option>
                  <option value="many">
                    many
                  </option>
                </select><b>{{ toName }}</b>
              </div>
            </label>
          </div>
        </form>

        <div class="submit-buttons flex justify-end gap-4">
          <a
            class="p-3 rounded-lg bg-transparent border-0 text-emphasis font-medium m-0 leading-4"
            data-cy="back"
            @click="wizardStep--"
          >
            Back
          </a>
          <a
            class="button save p-3 rounded-lg bg-gradient-primary border-0 text-base font-medium m-0 leading-4"
            data-cy="save"
            @click="onSave"
          >
            Add Connection
          </a>
        </div>
      </WizardStep>
    </div>
  </Modal>
</template>

<script>
import isNil from 'lodash/isNil';
import { mapGetters } from 'vuex';
import Modal from '@/components/ui/Modal';
import Icon from '@/components/ui/Icon';
import WizardStep from '@/components/ui/WizardSection';
import FieldUtils from '@/components/fields/FieldUtils';
import Field from '@/store/models/Field';
import RequestUtils from '@/components/util/RequestUtils';
import { trackEvent } from '@/lib/rudderstack-helper';

export default {
  components: {
    Modal,
    Icon,
    WizardStep,
  },
  mixins: [
    FieldUtils,
    RequestUtils,
  ],
  props: {
    baseUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rootURL: '',
      backLink: '',
      wizardStep: 1,
      connectedObjectKey: '',
      connectedHas: 'one',
      connectedBelongsTo: 'many',
    };
  },
  computed: {
    ...mapGetters([
      'objects',
      'objectsByMenuOrder',
    ]),

    connectedObject() {
      return this.$store.getters.getObject(this.connectedObjectKey);
    },

    fromName() {
      const inflection = (this.connectedHas === 'one') ? 'singular' : 'plural';

      return this.connectedObject.inflections[inflection];
    },

    toName() {
      const inflection = (this.connectedBelongsTo === 'one') ? 'singular' : 'plural';

      return this.object.inflections[inflection];
    },
  },

  // set the backlink to the previous route
  created() {
    this.connectedObjectKey = this.objectsByMenuOrder[0].key;

    // don't suggest the same object to connect to if possible
    if (this.connectedObjectKey === this.object.key && this.objectsByMenuOrder.length > 1) {
      this.connectedObjectKey = this.objectsByMenuOrder[1].key;
    }

    // set back link
    if (!isNil(this.baseUrl)) {
      this.backLink = this.baseUrl;

      return;
    }

    this.backLink = this.$route.path.split('/add')[0];
  },
  methods: {

    onSave() {
      let name = this.fromName;
      let nameCount = 2;

      while (this.object.getFieldByName(name)) {
        name = `${this.fromName} ${nameCount++}`;
      }

      const fieldNew = new Field({
        name,
        type: 'connection',
        relationship: {
          belongs_to: this.connectedBelongsTo,
          has: this.connectedHas,
          object: this.connectedObjectKey,
        },
      }, this.object);

      this.commitRequest({
        validate: () => fieldNew.validate(),
        request: () => fieldNew.create(),
        onSuccess: () => {
          this.$router.push(this.backLink);
          trackEvent('connection_added');
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
label div {
  font-weight: 400;
}
</style>
