<template>
  <div
    :style="{ animationDelay: `${animationDelay}ms !important`, opacity: 0 }"
    class="flex w-full animate-slideDownAndFade gap-2 rounded-lg bg-muted p-2 px-3 fill-mode-forwards"
  >
    <Icon
      :type="icon"
      :class="['fill-[url(#svg-gradient-2)] min-h-6 min-w-6 -mt-1', iconClass]"
    />
    <div class="flex flex-col gap-1 pb-1 pt-0.5">
      <div class="text-sm font-medium text-emphasis">
        {{ title }}
      </div>
      <div
        v-if="description"
        class="text-xs text-subtle"
      >
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon.vue';

export default {
  name: 'TemplateInfoItem',
  components: { Icon },
  props: {
    animationDelay: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null,
    },
  },
};
</script>
