<template>
  <WelcomeLink
    :animation-delay="animationDelay"
    :href="computedHref"
  >
    {{ type === 'preview' ? 'Preview My App' : 'Go To The App Builder' }}
  </WelcomeLink>
</template>

<script>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import WelcomeLink from '@/components/welcome/WelcomeLink.vue';

const buttonTypes = {
  preview: 'preview',
  builder: 'builder',
};

export default {
  name: 'TemplateButton',
  components: { WelcomeLink },
  props: {
    animationDelay: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const route = useRoute();
    const type = ref(route.query['button-type']);

    const computedHref = computed(() => (type.value === buttonTypes.preview ? '/live-app-preview' : '/'));

    return { computedHref, type, buttonTypes };
  },
};
</script>
