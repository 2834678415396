<template>
  <Ribbon
    v-if="isNewPage || page"
    id="page-ribbon"
    data-cy="page-ribbon"
  >
    <template v-if="page">
      <div class="ribbon_mainRow h-min pt-6 pb-3">
        <h2 class="ribbonName mr-12">
          <Icon
            type="page"
            class="ribbonName_icon text-default h-6 w-6"
          />

          <RouterLink
            :to="pageLink"
            class="ribbonName_link truncate text-emphasis text-xl"
            v-html="page.name"
          />

          <PageEditDropdown
            v-if="showSettings"
            :page="rawPage"
            :classes="{ trigger: `buttonSquare-outline -size-small -color-secondary hover:bg-brand-50 bg-transparent border-0 rounded`, triggerIcon: 'h-6 w-6 text-default' }"
          />
        </h2>

        <template v-if="isStandardPage">
          <div class="tabMenu p-0">
            <RouterLink
              v-slot="{ isActive, navigate }"
              :to="`${pageLink}`"
              custom
            >
              <button
                class="tabLink mr-2"
                :class="[
                  {'router-link-active bg-brand-100': isActive},
                  { 'hover:bg-brand-50': !isActive },
                  {'is-active': !rulesAreActive},
                  ribbonSpacingClass,
                  baseAnchorButtonClasses
                ]"
                data-cy="page-ribbon-views"
                @click="navigate"
              >
                <span class="tabLink_content mb-0">
                  <Icon
                    type="views"
                    class="tabLink_icon h-4 w-4"
                    :class="{
                      'fill-[url(#svg-brand-gradient)]': isActive,
                    }"
                  />
                  <span
                    class="tabLink_textLabel pl-1 text-default text-base font-normal"
                    :class="{ 'text-emphasis': isActive }"
                  >
                    Views
                  </span>
                  <CountIndicator
                    v-if="pageViewsCount"
                    class="text-default"
                    :class="{
                      'bg-muted': !isActive,
                      'bg-brand-50 text-emphasis': isActive
                    }"
                    :count="pageViewsCount"
                  />
                </span>
                <span class="tabLink_indicator hidden" />
              </button>
            </RouterLink>
            <RouterLink
              v-if="page.canHavePageRules()"
              v-slot="{ isActive, navigate }"
              :to="`${pageLink}/rules`"
              custom
            >
              <button
                class="tabLink mr-2"
                :class="[
                  {'router-link-active bg-brand-100': isActive},
                  { 'hover:bg-brand-50': !isActive },
                  {'is-active': rulesAreActive},
                  ribbonSpacingClass,
                  baseAnchorButtonClasses
                ]"
                data-cy="page-ribbon-rules"
                @click="navigate"
              >
                <span class="tabLink_content mb-0">
                  <Icon
                    type="rules"
                    class="tabLink_icon h-4 w-4"
                    :class="{
                      'fill-[url(#svg-brand-gradient)]': isActive,
                    }"
                  />
                  <span
                    class="tabLink_textLabel pl-1 text-default text-base font-normal"
                    :class="{ 'text-emphasis': isActive }"
                  >
                    Rules
                  </span>
                  <CountIndicator
                    v-if="pageRulesCount"
                    class="text-default"
                    :class="{
                      'bg-muted': !isActive,
                      'bg-brand-50 text-emphasis': isActive
                    }"
                    :count="pageRulesCount"
                  />
                </span>
                <span class="tabLink_indicator hidden" />
              </button>
            </RouterLink>
            <RouterLink
              v-slot="{ isActive, navigate }"
              :to="`${pageLink}/settings`"
              custom
            >
              <button
                class="tabLink mr-2"
                :class="[
                  {'router-link-active bg-brand-100': isActive},
                  { 'hover:bg-brand-50': !isActive },
                  ribbonSpacingClass,
                  baseAnchorButtonClasses
                ]"
                data-cy="page-ribbon-settings"
                @click="navigate"
              >
                <span class="tabLink_content mb-0">
                  <Icon
                    type="settings"
                    class="tabLink_icon h-4 w-4"
                    :class="{
                      'fill-[url(#svg-brand-gradient)]': isActive,
                    }"
                  />
                  <span
                    class="tabLink_textLabel pl-1 text-default text-base font-normal"
                  >
                    Settings
                  </span>
                </span>
              </button>
            </RouterLink>
          </div>
          <PageAccessInfoPopover
            ref="pageAccessInfoPopover"
            :popover-placement="pageAccessPopoverPlacement"
            :tooltip-placement="pageAccessTooltipPlacement"
            :page="page"
          />
          <div
            class="flex-end"
          >
            <IconTextButton
              v-tippy="page.parent
                ? 'Preview this page\'s start page in your live application'
                : 'Preview this page in your live application'"
              title="Preview"
              data-feature="preview_button"
              data-feature-x-offset="-26"
              data-feature-y-offset="-26"
              icon="play-run-arrow"
              label="Preview"
              @click="goToPreview"
            />
          </div>
        </template>
      </div>
      <div
        v-if="showSubRow"
        class="ribbon_subRow border-0 gap-2 py-3"
      >
        <RouterLink
          v-if="showAddViewLink"
          v-tippy
          data-feature="add_view_button"
          data-feature-x-offset="-26"
          data-feature-y-offset="-26"
          content="Add a view to this page"
          :to="'/pages/' + page.key + '/views/add'"
          class="iconLink ribbonLink ribbonLink-persist p-3 rounded-lg border border-solid border-default bg-white text-emphasis h-10 m-0 hover:bg-brand-50 hover:border-brand-600 group"
          data-cy="add-view"
          style="margin-left: -8px;"
        >
          <Icon
            type="plus-thin"
            class="green-text iconLink_icon iconLink_icon-add ribbonLink_icon text-default mr-0 group-hover:text-brand-400 h-4 w-4"
          />
          <RibbonLinkText
            text="Add View"
            class="text-base font-medium"
          />
        </RouterLink>

        <PageDataSourcePopover
          v-if="!isMenu && !isNewPage"
          :classes="{
            trigger: `iconLink ribbonLink hover:bg-brand-50`,
            triggerIcon: 'iconLink_icon ribbonLink_icon text-default',
            triggerText: 'ribbonLink_text text-emphasis text-base',
            triggerArrow: 'iconLink_icon iconLink_icon-arrowDown text-emphasis ml-2'
          }"
        />

        <template v-if="showPreviewRecordFilter">
          <ConnectionInput
            v-if="showPagePreviewRecordPicker"
            :key="`${page.key}PreviewRecords`"
            v-model="previewId"
            :field="identifierField"
            :options="previewOptions"
            :use-select-input="true"
            :multiple="false"
            class="ribbon_previewRecord"
          />
        </template>

        <a
          v-tippy
          :content="collapseViews ? 'Views are collapsed, click to expand' : 'Views are expanded, click to collapse'"
          data-cy="page-ribbon-collapse-views"
          :class="{'collapseViews': collapseViews}"
          class="iconLink ribbonLink ribbonLink_expandViews hover:bg-subtle"
          @click="onToggleViewCollapse"
        >
          <Icon
            type="arrow-expand"
            class="iconLink_icon expandViews_on text-default"
            style="margin: 0;"
          />
          <Icon
            type="arrow-collapse"
            class="iconLink_icon expandViews_off text-default"
            style="margin: 0;"
          />
        </a>

        <a
          v-tippy
          :content="showViewNames ? 'Hide view names' : 'Show view names'"
          data-cy="page-ribbon-toggle-names"
          class="iconLink ribbonLink ribbonLink_toggleNames hover:bg-subtle"
          :class="{'showingNames': showViewNames}"
          @click="onToggleViewNames"
        >
          <Icon
            type="id"
            class="iconLink_icon toggleNames_on text-default"
            style="margin: 0;"
          />
          <Icon
            type="id-none"
            class="iconLink_icon toggleNames_off text-default"
            style="margin: 0;"
          />
        </a>
      </div>
    </template>
  </Ribbon>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapGetters } from 'vuex';

import ConnectionInput from '@/components/renderer/form/inputs/Connection';
import CountIndicator from '@/components/ui/CountIndicator';
import Ribbon from '@/components/layout/Ribbon';
import RibbonLinkText from '@/components/layout/RibbonLinkText';
import Icon from '@/components/ui/Icon';
import IconTextButton from '@/components/ui/IconTextButton';
import PageAccessInfoPopover from '@/components/pages/page/PageAccessInfoPopover';
import PageDataSourcePopover from '@/components/pages/PageDataSourcePopover';
import PageEditDropdown from '@/components/pages/PageEditDropdown';
import { liveAppUrl } from '@/lib/url-helper';
import { eventBus } from '@/store/bus';
import { completeChecklistStep } from '@/checklists/helpers';
import { trackEvent } from '@/lib/rudderstack-helper';
import { trackIntercomEvent } from '@/lib/intercom-helper';

export default {
  components: {
    ConnectionInput,
    CountIndicator,
    Icon,
    PageAccessInfoPopover,
    PageDataSourcePopover,
    PageEditDropdown,
    Ribbon,
    RibbonLinkText,
    IconTextButton,
  },
  emits: ['connection'], // from ConnectionInput component
  computed: {
    ...mapGetters([
      'getObject',
      'getField',
      'pagePreviewType',
      'showViewNames',
      'collapseViews',
      'hasFeature',
    ]),
    ...mapGetters('ui', [
      'ribbonSpacingClass',
      'rightBodyWidth',
      'rightPanelBreakpointWidths',
    ]),
    showSubRow() {
      if (this.isMenu) {
        return false;
      }

      // new pages don't show the tabs nor need collapse or name options
      return !this.isNewPage;
    },
    isStandardPage() {
      return (this.page && !this.isNewPage && !this.isMenu);
    },
    isNewPage() {
      return Boolean(this.page && this.page.isNewPage());
    },
    isMenu() {
      return this.page.type === 'menu';
    },
    page() {
      return this.$store.getters.activePage;
    },
    rawPage() {
      return this.$store.getters['page/getPageByKey'](this.page.key);
    },
    pageLink() {
      return `${this.page.getPagePath}`;
    },
    pageRulesCount() {
      return this.page.getPageRulesCount();
    },
    pageViewsCount() {
      return this.page.getViewsCount();
    },
    rulesAreActive() {
      return this.$route.path.includes('/rules');
    },
    showPagePreviewRecordPicker() {
      if (!this.previewId) {
        return false;
      }

      if (isEmpty(this.previewOptions)) {
        return false;
      }

      return true;
    },
    showAddViewLink() {
      return this.isStandardPage;
    },
    showSettings() {
      return this.page && this.page.key !== 'new';
    },
    showPreviewRecordFilter() {
      if (this.pagePreviewType !== 'live') {
        return false;
      }

      return this.page.canPreviewRecords();
    },
    liveAppUrl() {
      const pageSlug = this.page.getSlugPathForLiveAppUrl();

      return liveAppUrl(pageSlug);
    },
    // previewId is always set in the UserInterface store. It's initially created by the page when it loads.
    previewId: {
      get() {
        return this.$store.getters.pagePreviewId;
      },
      set(val) {
        if (isEmpty(val)) {
          // Do not allow the last value to be removed.
          return;
        }

        this.$store.dispatch('pagePreviewId', val[0].id);

        // emit
        eventBus.$emit('changePreviewId', val[0].id);
      },
    },
    previewOptions() {
      return this.$store.getters.pagePreviewOptions();
    },
    identifierField() {
      return this.getField(this.object.identifier);
    },
    object() {
      return this.page.getPagePreviewObject();
    },
    pageAccessPopoverPlacement() {
      const [firstBreakpoint] = this.rightPanelBreakpointWidths;

      return this.rightBodyWidth < firstBreakpoint ? 'bottom-start' : 'bottom-end';
    },
    pageAccessTooltipPlacement() {
      return this.pageAccessPopoverPlacement
        .includes('start') ? 'left' : 'bottom';
    },
    baseAnchorButtonClasses() {
      return 'flex justify-center items-center rounded-lg m-0 font-medium text-base capitalize px-2 py-1.5';
    },
  },
  watch: {
    ribbonSpacingClass() {
      // hide page access info popover when a ribbon spacing breakpoint is hit
      const { pageAccessInfoPopover } = this.$refs;
      if (pageAccessInfoPopover && pageAccessInfoPopover.isOpen) {
        pageAccessInfoPopover.hide();
      }
    },
  },
  methods: {
    onToggleViewNames() {
      this.$store.commit('toggleViewNames');
    },
    onToggleViewCollapse() {
      this.$store.commit('toggleViewCollapse');
    },
    logLiveAppAccess(sceneKey, source) {
      trackEvent('viewed_live_app', {
        key: sceneKey,
        source: source ?? 'page_ribbon',
      });
      trackIntercomEvent('viewed_live_app');
    },
    goToPreview() {
      this.logLiveAppAccess(this.page.parent || this.page.slug, 'clicked_preview_button');
      this.$router.push({ path: `/pages/${this.page.key}/live-app-preview/${this.page.slug}` });
      completeChecklistStep('preview_page');
    },
  },
};
</script>

<style lang="scss" scoped>
.ribbonLink_toggleNames:not(.showingNames) {

  .toggleNames_off {
    display: block;
  }

  .toggleNames_on {
    display: none;
  }

  &:hover {
    .toggleNames_off {
      display: none;
    }

    .toggleNames_on {
      display: block;
    }
  }
}
.ribbonLink_toggleNames.showingNames {

  .toggleNames_off {
    display: none;
  }

  .toggleNames_on {
    display: block;
  }

  &:hover {
    .toggleNames_off {
      display: block;
    }

    .toggleNames_on {
      display: none;
    }
  }
}
.ribbonLink_expandViews.collapseViews {

  .expandViews_off {
    display: block;
  }

  .expandViews_on {
    display: none;
  }

  &:hover {
    .expandViews_off {
      display: none;
    }

    .expandViews_on {
      display: block;
    }
  }
}
.ribbonLink_expandViews:not(.collapseViews) {

  .expandViews_off {
    display: none;
  }

  .expandViews_on {
    display: block;
  }

  &:hover {
    .expandViews_off {
      display: block;
    }

    .expandViews_on {
      display: none;
    }
  }
}

#page-ribbon {

  .multiselect {
    min-height: 28px;
    height: 28px;
  }

  .multiselect__tags {
    min-height: 100%;
    max-height: 100%;
  }

  .multiselect__single {
    min-height: 100%;
    max-height: 100%;
    font-size: .933em;
  }

  .icon-more-horiz {
    transform: rotate(-90deg);
  }

  section {

    > .popover {
      display: flex;
      align-items: center;
    }
  }

  h2 .icon-page {
    margin-right: 5px;
  }
}

#page-preview-label {
  padding-right: 1px;

  svg {
    width: 12px;
    height: 12px;
  }
}

#page-preview-type {
  padding-left: 0px;
}

#page-ribbon .ribbon_previewRecord {
  max-height: 30px;
  width: 180px;
  margin-right: 16px;
  min-width: 50px;
  font-size: .925em;
}

#page-ribbon .flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  flex: 1 1 auto;
}
</style>
