<template>
  <div class="mx-auto flex h-screen max-w-[472px] flex-col items-center gap-8 pt-[10%]">
    <ConfettiAnimation />
    <div
      class="flex animate-slideDownAndFade flex-col gap-4 delay-1000 fill-mode-forwards"
      style="opacity: 0;"
    >
      <div class="text-center text-2xl font-medium text-emphasis">
        Congratulations, Your <b>{{ appName }}</b> App Is Ready!
      </div>
      <div class="text-center text-subtle text-sm">
        We’ve set up the essential building blocks for you. These are the foundation of every successful Knack app.
      </div>
    </div>
    <div
      v-if="type === 'template'"
      class="flex flex-col gap-4 w-full"
    >
      <TemplateInfoItem
        :animation-delay="2000"
        icon="table-cells"
        icon-class="h-6 w-6 !mt-0"
        title="Data"
        :description="templateDataDescription"
      />
      <TemplateInfoItem
        :animation-delay="3000"
        icon="page"
        title="Pages"
        description="We’ve set up a dashboard page and a couple of other pages where users can interact with your data."
      />
      <TemplateInfoItem
        :animation-delay="4000"
        icon="user-group"
        title="Users"
        :description="templateUsersDescription"
      />
    </div>
    <div
      v-else
      class="flex flex-col gap-4 w-full"
    >
      <AiInfoItem
        :animation-delay="2000"
        icon="table-cells"
        :title="aiDataTitle"
        :description="aiDataDescription"
      />
      <AiInfoItem
        :animation-delay="3000"
        icon="page"
        title="5+ Pages"
        description="dashboards, forms and more"
      />
      <AiInfoItem
        :animation-delay="4000"
        icon="user-group"
        :title="aiUsersTitle"
        description="admin role included"
      />
    </div>
    <template v-if="type === 'template'">
      <TemplateButton :animation-delay="5000" />
    </template>
    <template v-else>
      <WelcomeLink
        :animation-delay="5000"
        href="/live-app-preview"
      >
        Preview And Edit My App
      </WelcomeLink>
    </template>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AiInfoItem from '@/components/welcome/AiInfoItem.vue';
import TemplateButton from '@/components/welcome/TemplateButton.vue';
import WelcomeLink from '@/components/welcome/WelcomeLink.vue';
import ConfettiAnimation from '@/components/welcome/ConfettiAnimation.vue';
import TemplateInfoItem from '@/components/welcome/TemplateInfoItem.vue';

export default {
  name: 'WelcomePage',
  components: {
    TemplateInfoItem,
    ConfettiAnimation,
    AiInfoItem,
    TemplateButton,
    WelcomeLink,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const type = ref(route.query.type);

    const appName = computed(() => route.query['app-name'] || '');

    if (!type.value) {
      router.push('/');
    }

    return {
      type,
      appName,
    };
  },
  computed: {
    aiDataTitle() {
      return `${this.$store.getters.standardObjects.length} Data Tables`;
    },
    aiDataDescription() {
      const objects = this.$store.getters.standardObjects;

      const [firstTableName, secondTableName] = objects.map((table) => table.name);

      if (objects.length === 1) {
        return firstTableName;
      } if (objects.length === 2) {
        return `${firstTableName} and ${secondTableName}`;
      } if (objects.length > 2) {
        return `${firstTableName}, ${secondTableName} and more`;
      }

      return '';
    },
    aiUsersTitle() {
      return `${this.$store.getters.userObjects.length} User Roles`;
    },
    templateDataDescription() {
      return `We’ve created ${this.$route.query.table || 'a default'} table for you as a starting point.`;
    },
    templateUsersDescription() {
      return `Your app comes with ${this.$route.query['user-role'] || 'a default'} user role. Create new roles to tailor what a user group sees.`;
    },
  },
};
</script>
