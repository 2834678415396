<template>
  <div
    id="socket-notification"
    class="fixed z-[10] left-2/4 bottom-[30px] bg-intense w-[364px] p-4 rounded-xl"
    data-cy="notification"
  >
    <div
      class="flex items-center"
    >
      <div
        v-if="isLoading || notification.category === 'queued'"
        class="flex items-center flex-1"
      >
        <Icon
          type="loading-spinner"
          :class="iconClasses"
        />
        <span class="text-inverted text-sm font-medium">
          {{ title }}
        </span>
      </div>

      <div v-else-if="notification.category === 'indexing'">
        <div class="flex items-center flex-1 mb-1">
          <Icon
            v-if="icon"
            type="loading-spinner"
            :class="iconClasses"
          />
          <span class="text-inverted text-sm font-medium">
            {{ title }} - Indexing Records
          </span>
        </div>
        <p class="mb-0 text-inverted text-sm">
          Whenever a record is updated, other records may need to be
          processed due to connections, formulas, and equations.
        </p>
      </div>

      <template v-else>
        <div class="flex-1 mr-4">
          <div class="flex items-center">
            <Icon
              v-if="icon"
              :type="icon"
              :class="iconClasses"
            />
            <span class="text-inverted text-sm font-medium">
              {{ title }}
            </span>
          </div>

          <template v-if="showCancelConfirmation">
            <div class="flex items-center mt-2">
              <button
                type="button"
                class="p-2 mr-2 appearance-none border-none rounded-lg text-base text-inverted font-medium bg-red-600"
                @click="onConfirmCancelClicked"
              >
                Cancel {{ notification.jobType }}
              </button>
              <button
                type="button"
                class="p-2 appearance-none border-none rounded-lg text-base text-inverted font-medium
                bg-transparent hover:bg-overlay"
                @click="showCancelConfirmation = false"
              >
                Keep {{ jobAction }}
              </button>
            </div>
          </template>

          <template v-else>
            <div
              v-if="notification.category !== 'canceled'"
              class="flex items-center mt-2"
            >
              <progress
                class="h-2 w-full rounded-lg mr-2"
                :max="notification.total_count"
                :value="progressValue"
              />
              <span class="text-inverted text-xs">
                {{ percentageComplete }}
              </span>
            </div>
            <p class="mb-0 mt-2 text-xs text-inverted">
              {{ description }}
            </p>
          </template>
        </div>
        <div v-if="!showCancelConfirmation">
          <button
            v-if="shouldShowCloseButton"
            class="p-2 appearance-none border-none rounded-lg text-base text-inverted font-medium
            bg-transparent hover:bg-overlay"
            @click="$emit('closeClicked', jobType)"
          >
            Close
          </button>
          <button
            v-else
            class="p-2 appearance-none border-none rounded-lg text-base text-inverted font-medium
            bg-transparent hover:bg-overlay"
            @click="showCancelConfirmation = true"
          >
            Cancel
          </button>
        </div>
      </template>
    </div>
    <div
      v-if="shouldShowFailedRowsMessage"
      class="mt-2 text-xs">
      <div class="flex items-center justify-between text-inverted">
        <span class="font-bold">
          Date/time entries on these rows failed to import
        </span>
        <button
          type="button"
          class="bg-transparent border-none"
          @click="toggleFailedRowsVisibility"
        >
          <Icon
            :type="showFailedRowsList ? 'chevron-up' : 'chevron-down'"
            class="cursor-pointer h-5 w-5"
          />
        </button>
      </div>
      <div
        v-if="showFailedRowsList"
        class="overflow-y-auto max-h-16 text-xs py-0.5 px-1 rounded gap-1 bg-[#E0DCDF]"
      >
        {{ notification.failed_rows.join(', ') }}
      </div>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import capitalize from 'lodash/capitalize';
import Icon from '@/components/ui/Icon';
import FontAwesome from '@/components/ui/FontAwesome';

export default {
  components: {
    Icon,
    FontAwesome,
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    object: {
      type: Object,
      required: true,
    },
  },
  emits: ['confirmCancelClicked', 'closeClicked'],
  data() {
    return {
      showCancelConfirmation: false,
      showFailedRowsList: false,
      processedCount: 0,
      intervalId: null,
    };
  },
  computed: {
    title() {
      if (this.isLoading) {
        return 'Loading progress...';
      }
      if (this.notification.category === 'queued') {
        return `Processing ${this.notification.jobType}...`;
      }
      if (this.notification.category === 'progress' || this.notification.category === 'indexing') {
        return `${capitalize(this.jobAction)} ${this.object.name}`;
      }
      if (this.notification.category === 'complete') {
        return `${capitalize(this.notification.jobType)} ${this.object.name} Complete`;
      }
      if (this.notification.category === 'canceled') {
        return `${capitalize(this.notification.jobType)} ${this.object.name} Canceled`;
      }
      if (this.notification.category === 'retry') {
        return `Retrying ${capitalize(this.notification.jobType)} of ${this.object.name}`;
      }
      if (this.notification.category === 'failed') {
        return `${this.object.name} ${capitalize(this.notification.jobType)} Failed`;
      }
      if (this.showCancelConfirmation) {
        return `Cancel ${capitalize(this.jobAction)} ${this.object.name}`;
      }
      return '';
    },
    icon() {
      if (this.notification.category === 'complete') {
        if (!_.isEmpty(this.notification.failed_rows)) {
          return 'exclamation-circle';
        }
        return 'check-circle';
      }
      if (this.notification.category === 'failed') {
        return 'exclamation-circle';
      }

      switch (this.notification.jobType) {
        case 'import':
          return 'cloud-import';
        case 'update':
          return 'edit';
        case 'delete':
          return 'delete';
        case 'task':
          return 'action-task';
        default:
          return 'info-circle';
      }
    },
    iconClasses() {
      const classes = ['h-4 w-4 mr-2 shrink-0'];

      if (this.isLoading) {
        classes.push('text-inverted');
        return classes;
      }

      if (this.notification.category === 'complete') {
        classes.push('text-green-400');
      } else if (this.notification.category === 'failed') {
        classes.push('text-red-600');
      } else {
        classes.push('text-inverted');
      }

      return classes;
    },
    description() {
      switch (this.notification.category) {
        case 'complete':
          if (this.notification.jobType !== 'task' && this.processedCount === 0 && this.notification.total_count === 0) {
            return '';
          }
          return `${this.notification.total_count} records processed ${this.timeago}`;
        case 'canceled':
          return `${this.notification.processed_count} records processed ${this.timeago}`;
        case 'failed':
          return `${this.notification.processed_count} of ${this.notification.total_count} processed before fail`;
        default:
          return `${this.processedCount} of ${this.notification.total_count} processed`;
      }
    },
    isLoading() {
      return this.object.status !== 'current' && isEmpty(this.notification);
    },
    jobAction() {
      return `${this.notification.jobType.endsWith('e') ? this.notification.jobType.slice(0, this.notification.jobType.length - 1) : this.notification.jobType}ing`;
    },
    percentageComplete() {
      if (this.processedCount === 0 && this.notification.total_count === 0) {
        return '';
      }
      if (this.notification.category === 'complete' && this.processedCount === 0) {
        return '100%';
      }
      return `${Math.round((this.processedCount / this.notification.total_count) * 100)}%`;
    },
    progressValue() {
      if (this.processedCount === 0 && this.notification.total_count === 0) {
        return 1;
      }
      if (this.notification.category === 'complete' && this.processedCount === 0) {
        return this.notification.total_count;
      }
      return this.processedCount > 0 ? this.processedCount : undefined;
    },
    timeago() {
      return moment.unix(this.notification.timestamp).fromNow();
    },
    shouldShowCloseButton() {
      return this.notification.category === 'complete'
        || this.notification.category === 'canceled'
        || this.notification.category === 'failed';
    },
    shouldShowFailedRowsMessage() {
      return (this.notification.category === 'complete' || this.notification.category === 'canceled') && !_.isEmpty(this.notification.failed_rows);
    },
  },
  created() {
    this.intervalId = setInterval(() => {
      this.processedCount = this.notification.processed_count;
    }, 1000);
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    onConfirmCancelClicked() {
      this.showCancelConfirmation = false;
      this.$emit('confirmCancelClicked', this.notification.jobType);
    },
    toggleFailedRowsVisibility() {
      this.showFailedRowsList = !this.showFailedRowsList;
    },
  },
};
</script>
